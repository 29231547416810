import React from "react";
import Carousel from "react-bootstrap/Carousel";


// Images
function CarouselBanner({ hotelCarData }) {
  return (
    <>
      <Carousel fade className="h-96 hotelCar" indicators={false}>
        {hotelCarData.map((carData, idx) => {
          return (
            <Carousel.Item key={idx} className="h-full">
              <img
                className="d-block w-100 object-cover"
                src={carData.media}
                alt="First slide"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}

export default CarouselBanner;
