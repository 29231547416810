import { useEffect } from "react";
import Banner from "./Banner";
import HotelAmenties from "./HotelAmenties";
import HotelExtras from "./HotelExtras";
import config from "../../../config";
import axios from "axios";
import { connect } from "react-redux";
import { getLanding } from "../../../services/actions";
import { Row } from "react-bootstrap";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function LandingPage({ landingPageData, getLangingPageData }) {
  var token = cookies.get("nw_token");

  const getLandingData = () => {
    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${config.base_url}Hotelmanager/getLandingPage`,
    })
      .then(function (response) {
        getLangingPageData(response.data.landingPageData);
      })
      .catch(function (err) {
        // console.log(err);
        // alert(err.response.data.message);
      });
  };

  useEffect(() => {
    getLandingData();
  }, []);
  return (
    <>
      <div className="max-1400">
        <section className="mt-4">
          <Banner />
        </section>
        <section className="mt-4">
          <Row>
            {landingPageData?.hotelExtraSection.map((data, idx) => {
              return <HotelAmenties key={idx} amenData={data} id={idx} />;
            })}
          </Row>
        </section>
        <section className="mt-4 mb-5">
          <HotelExtras />
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    getLangingPageData: (value) => {
      dispatch(getLanding(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToState)(LandingPage);
