import React from "react";
import { connect } from "react-redux";
import { setHotelToManager,removeHotelAssigned } from "../../../services/actions";
import ClearIcon from "@material-ui/icons/Clear";
function HotelResult({ hotelData, setvalue, setHotelToManagers, hotelAssigned,listData,removeHotelAssigneds }) {
  const getHotelData = (data) => {
    setHotelToManagers(data);
  };
  return (
    <>
      {!hotelAssigned ? (
        <div
          className="flex bg-gray-200 hover:bg-gray-300 mb-2 items-center px-4 cursor-pointer"
          onClick={() =>{ getHotelData(hotelData); setvalue("")}}
        >
          <img
            src={hotelData.bannerImage}
            alt="logo"
            className="mr-3 w-16 h-16 object-contain"
          />
          <span className=" text-sm font-semibold block" style={{ flex: 1 }}>
            {hotelData.name}
          </span>
        </div>
      ) : (
        <div className="flex items-center bg-gray-300 px-4 mb-3 rounded">
          <img
            src={listData.bannerImage}
            alt="logo"
            className="mr-3 w-16 h-16 object-contain"
          />
          <span className=" text-sm font-semibold block" style={{ flex: 1 }}>
            {listData.name}
          </span>
          <div>
            <ClearIcon className="cursor-pointer" onClick={() => removeHotelAssigneds(listData.hotelId)}/>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setHotelToManagers: (value) => {
      dispatch(setHotelToManager(value));
    },
    removeHotelAssigneds: (value) => {
      dispatch(removeHotelAssigned(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(HotelResult);
