import React from "react";
import onClickOutside from "react-onclickoutside";
import HotelResult from "./HotelResult";
function HotelSerch({close,hotelResult ,setvalue}) {
    HotelSerch.handleClickOutside = () => close();
  return (
    <>
      <div className="bg-white px-3 py-3 mt-3 rounded">
        {hotelResult?.slice(0,4).map((hotelData,idx) => {
          return <HotelResult setvalue={setvalue} key={idx} hotelData={hotelData}/>
        })}
      </div>
    </>
  );
}
const clickOutsideConfig = {
    handleClickOutside: () => HotelSerch.handleClickOutside
  };
  

export default onClickOutside(HotelSerch, clickOutsideConfig);
