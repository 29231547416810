import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import DashboardCard from "../../../components/forms/cards/DashboardCard";

import manager from "../../../assets/images/manager.jpeg"
import hotel from "../../../assets/images/sleep.jpeg"
import landing from "../../../assets/images/landing.jpeg"
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom"
const cookies = new Cookies();
function Dashboard() {
  var user = cookies.get("nw_post");
  var history = useHistory();
  return (
    <React.Fragment>
      {user === "admin" ? <Container className="py-4">
        <Row>
          <Col md={3}>
            <DashboardCard hotelId={"managehotel"} title={"Manage Hotel"} view="admin" icon={hotel} />
          </Col>
          <Col md={3}>
            <DashboardCard hotelId={"manageuser"} title={"Manager user"} view="admin" icon={manager} />
          </Col>
          {/* <Col md={3}>
            <DashboardCard hotelId={"landingpage"} title={"Manage Landing Page"} view="admin" icon={landing} />
          </Col> */}
        </Row>
      </Container> : history.replace('/')}
    </React.Fragment>
  );
}

export default Dashboard;
