import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CarouselBanner from "../carouselBanner/CarouselBanner";
import FeatureRoom from "../FeatureRoom/FeatureRoom";
import Button from "../forms/button/Button";
import HotelFeature from "../hotelFeature/HotelFeature";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import PublishIcon from "@material-ui/icons/Publish";
import ClearIcon from "@material-ui/icons/Clear";
import {
  setHotelPageTitle,
  setHotelPageDescription,
  fetchImageLibrary,
  setMediaForHotelBanner,
  unCheckMediaForHotelBanner,
} from "../../services/actions";
import Modals from "../modal/Modals";
import CarLightBox from "./CarLightBox";
import Cookies from "universal-cookie";
import { RxReload } from "react-icons/rx";
const cookies = new Cookies();
function HotelPage({
  hotelPageData,
  globalReducer,
  setHotelPageTitles,
  setHotelPageDescriptions,
  fetchImageLibrarys,
  setMediaForHotelBanners,
  unCheckMediaForHotelBanners,
}) {
  var token = cookies.get("nw_token");
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    addImage(file);
  };
  const addImage = (file) => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("action", "ADD");
    fd.append("hotelImage", file);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getHotelMedia = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getMediaLibrary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchImageLibrarys(response.data.mediaList);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const saveHotelPage = () => {
    setIsLoading(true)
    var fd = new FormData();
    // console.log(hotelPageData.amenitiesContaierSendData)
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("components", "12");
    fd.append("bannerCarousel", JSON.stringify(hotelPageData.hotelBannerId));
    fd.append(
      "amenitiesContaier",
      JSON.stringify(hotelPageData.amenitiesContaierSendData)
    );
    fd.append(
      "addOnContaier",
      JSON.stringify(hotelPageData.addOnContaierSendData)
    );
    fd.append("titleContaier", JSON.stringify(hotelPageData.titleContainer));

    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/updateHotelPage`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        if (response.data.message === "Updated") {
          alert("Page Updated");
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };
  const openImageGallery = () => {
    setIsModalOpen(true);
  };
  const setImage = (e, mediaData) => {
    var parentElem = e.target.parentNode;
    if (parentElem.classList.contains("activeImageCheck")) {
      parentElem.classList.remove("activeImageCheck");
      unCheckMediaForHotelBanners(mediaData);
    } else {
      parentElem.classList.add("activeImageCheck");
      setMediaForHotelBanners(mediaData);
    }
  };
  const deleteImage = (e, mediaId) => {
    e.preventDefault();
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("action", "DEL");
    fd.append("mediaId", mediaId);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [isRemoveClicked, setRemoveClicked] = useState(false)
  return (
    <>
      <Row>
        <Col md={6}>
          <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
            Banner
          </label>
        </Col>
        <Col md={6} className="text-right">
          <Button
            className="rounded py-2 px-5 text-white roboto"
            type="button"
            style={{ backgroundColor: "var(--primaryHotel)" }}
            onClick={() => openImageGallery()}
          >
            Add Images
          </Button>
        </Col>
        <Col md={12} className="mt-4 mb-4">
          <div className="h-96">
            <CarouselBanner hotelCarData={hotelPageData.hotelBannerCar} />
          </div>
        </Col>
        <Col md={6} className="">
          <div className="w-full mb-4">
            <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
              Title
            </label>
            <input
              className="nr_form_control"
              type="text"
              value={hotelPageData.titleContainer_Title}
              onChange={(e) => setHotelPageTitles(e.target.value)}
            />
          </div>
        </Col>
        <Col md={6} className="">
          <div className="w-full mb-4">
            <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
              Description
            </label>
            <textarea
              className="nr_form_control"
              style={{ height: "210px" }}
              value={hotelPageData.titleContainer_Content}
              onChange={(e) => setHotelPageDescriptions(e.target.value)}
            ></textarea>
          </div>
        </Col>
      </Row>
      {hotelPageData.amenitiesContainer.map((amenData, idx) => {
        if (idx % 2 === 0) {
          return (
            <FeatureRoom
              key={idx}
              position="right"
              amenData={amenData}
              id={idx}
            />
          );
        } else {
          return (
            <FeatureRoom
              key={idx}
              position="left"
              amenData={amenData}
              id={idx}
            />
          );
        }
      })}
      <Row className="border border-gray-300 px-3 py-4 rounded">
        {hotelPageData.addOnContaier.map((addOnData, idx) => {
          return <HotelFeature key={idx} addOnData={addOnData} id={idx} />;
        })}
      </Row>
      <Col md={12} className="px-0 mt-6">
        <Button
          className="rounded py-2 px-5 text-white roboto"
          type="button"
          disabled={isLoading}
          style={{ backgroundColor: "var(--primaryHotel)" }}
          onClick={() => saveHotelPage()}
        >
          <div className="flex items-center justify-center">
            Save
            {isLoading && <RxReload className=" ml-2 w-4 h-4 animate-spin" />}
          </div>
        </Button>
      </Col>
      <Modals
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size={"lg"}
        className={"bannerImageModal"}
      >
        <Container fluid className="">
          <Row className="py-2 border-b border-gray-300">
            <Col md={12}>
              <div className="">
                <h3 className="mb-0">Add Image</h3>
              </div>
            </Col>
          </Row>
          <Row className="py-2 px-2 carouselModalHeight overflow-y-scroll">
            <Col md={3}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <div
                onClick={handleImageClick}
                className="h-40 flex flex-col cursor-pointer rounded hover:shadow border-2 items-center justify-center text-center text-gray-400 border-dashed border-gray-300"
              >
                <PublishIcon />
                click To Upload
              </div>
            </Col>
            {globalReducer?.mediaLibrary.map((mediaData, idx) => {
              return (
                <Col md={3} key={idx} className="mb-2 px-1">
                  <div
                    className={`cursor-pointer h-40 relative z-50 hasDeleteHover overflow-hidden`}
                    data-id={mediaData.mediaId}
                  >
                    <ClearIcon className={""} style={{ display: "none" }} />
                    <img
                      src={mediaData.media}
                      alt="img"
                      className="object-cover h-full w-full"
                      onClick={(e) => setImage(e, mediaData)}
                    />
                    <button
                      style={{ backgroundColor: "#fbb13c" }}
                      className="absolute font-san py-1 text-sm deleteModalImage w-full"
                      onClick={(e) => deleteImage(e, mediaData.mediaId)}
                    >
                      Delete
                    </button>
                  </div>
                </Col>
              );
            })}

          </Row>
          <Row className="py-2 border-t border-gray-300">
            <Col md={12}>
              <div className=" text-right">
                <Button
                  className="rounded py-2 px-3 mr-2 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => setRemoveClicked(!isRemoveClicked)}
                >
                  {isRemoveClicked ? "Close" : "Remove images"}
                </Button>
                <Button
                  className="rounded py-2 px-3 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => setIsModalOpen(false)}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
          {globalReducer?.mediaLibrary.length > 0 ? <Row className={`lighboxContainer absolute w-full ${isRemoveClicked ? "lightBoxUp" : ""}`}>
            <CarLightBox isRemoveClicked />
          </Row> : ""}
        </Container>
      </Modals>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setHotelPageTitles: (value) => {
      dispatch(setHotelPageTitle(value));
    },
    setHotelPageDescriptions: (value) => {
      dispatch(setHotelPageDescription(value));
    },
    fetchImageLibrarys: (value) => {
      dispatch(fetchImageLibrary(value));
    },
    setMediaForHotelBanners: (value) => {
      dispatch(setMediaForHotelBanner(value));
    },
    unCheckMediaForHotelBanners: (value) => {
      dispatch(unCheckMediaForHotelBanner(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(HotelPage);
