import {
  GET_ROOM_DETAIL_DATA,
  SET_HOTEL_DETAIL_IMAGE,
  SET_HOTEL_NAME,
  SET_HOTEL_DESCRIPTION,
  SET_HOTEL_ADDRESS,
  SET_HOTEL_PINCODE,
  SET_HOTEL_CONTACT,
  SET_HOTEL_CHECKIN,
  SET_HOTEL_CHECKOUT,
  ADD_HOTEL_AMEN_FEAT
} from "../constant";
const initialFormsData = {
  roomdetails: "",
  hotelRoomImage: "",
  hotelName: "",
  hotelDescription: "",
  hotelAddress: "",
  hotelPincode: "",
  hotelContact: "",
  hotelCheckIn: "",
  hotelCheckOut: "",
  hotelRoomImageFile: "",
  hotelAmen: []
};

const roomDetailData = (state = initialFormsData, action) => {
  switch (action.type) {
    case GET_ROOM_DETAIL_DATA: {
      // state.hotelAmen = action.payload.amenities;
      return {
        ...state,
        hotelRoomImage: action.payload[0].media,
        hotelName: action.payload[0].name,
        hotelDescription: action.payload[0].description,
        hotelAddress: action.payload[0].address,
        hotelPincode: action.payload[0].pincode,
        hotelContact: action.payload[0].contact,
        hotelCheckIn: action.payload[0].checkIn,
        hotelCheckOut: action.payload[0].checkOut,
        hotelAmen: action.payload.amenities
      };
    }
    case SET_HOTEL_DETAIL_IMAGE: {
      state.hotelRoomImageFile = action.payload;
      state.hotelRoomImage = URL.createObjectURL(action.payload);
      return {
        ...state,
      };
    }
    case SET_HOTEL_NAME: {
      return {
        ...state,
        hotelName: action.payload
      };
    }
    case SET_HOTEL_DESCRIPTION: {
      return {
        ...state,
        hotelDescription: action.payload
      };
    }
    case SET_HOTEL_ADDRESS: {
      return {
        ...state,
        hotelAddress: action.payload
      };
    }
    case SET_HOTEL_PINCODE: {
      return {
        ...state,
        hotelPincode: action.payload
      };
    }
    case SET_HOTEL_CONTACT: {
      return {
        ...state,
        hotelContact: action.payload
      };
    }
    case SET_HOTEL_CHECKIN: {
      return {
        ...state,
        hotelCheckIn: action.payload
      };
    }
    case SET_HOTEL_CHECKOUT: {
      return {
        ...state,
        hotelCheckOut: action.payload
      };
    }
    case ADD_HOTEL_AMEN_FEAT: {
      state.hotelAmen = JSON.stringify(action.payload);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default roomDetailData;
