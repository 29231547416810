import "./App.css";
import Login from "./pages/authentication/Login";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import Header from "./components/header/Header";
import Hotel from "./pages/admin/hotel/Hotel";
import Footer from "./components/footer/Footer";
import LandingPage from "./pages/admin/Landingpage/LandingPage";
import ManagerDashboard from "./pages/admin/manager/ManagerDashboard/ManagerDashboard";
import ManagerHotel from "./pages/admin/manageHotel/ManagerHotel";
import ManagerUser from "./pages/manageUser/ManagerUser";
import CreateUser from "./pages/admin/create/CreateUser";
import CreateHotel from "./pages/admin/create/CreateHotel";
import EditHotel from "./pages/admin/edit/EditHotel";
import EditUser from "./pages/admin/edit/EditUser";
import HotelsListing from "./pages/admin/hotel/HotelsListing";
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="app">
      <Toaster position="top-right" />
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/dashboard" exact>
          <Header user={"Admin"} />
          <Dashboard />
        </Route>
        <Route path="/hotel-dashboard" exact>
          <Header user={"Manager"} />
          <ManagerDashboard />
        </Route>
        <Route path="/hotels-listing" exact>
          <Header user={"Manager"} />
          <HotelsListing />
        </Route>
        <Route path="/hotel/:id" exact>
          <Header user={"Manager"} />
          <Hotel />
          <Footer />
        </Route>
        <Route path="/landingpage" exact>
          <Header user={"Admin"} />
          <LandingPage />
          <Footer />
        </Route>
        <Route path="/managehotel" exact>
          <Header user={"Admin"} />
          <ManagerHotel />
          <Footer />
        </Route>
        <Route path="/manageuser" exact>
          <Header user={"Admin"} />
          <ManagerUser />
          <Footer />
        </Route>
        <Route path="/createuser" exact>
          <Header user={"Admin"} />
          <CreateUser />
          <Footer />
        </Route>
        <Route path="/createhotel" exact>
          <Header user={"Admin"} />
          <CreateHotel />
          <Footer />
        </Route>
        <Route path="/edithotel/:id" exact>
          <Header user={"Admin"} />
          <EditHotel />
          <Footer />
        </Route>
        <Route path="/edituser/:id" exact>
          <Header user={"Admin"} />
          <EditUser/>
          <Footer />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
