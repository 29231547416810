import React from 'react'

function Button(props) {
    return (
        <React.Fragment>
            <button {...props}>
                {props.children}
            </button>
        </React.Fragment>
    )
}

export default Button
