import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Button from "../../../components/forms/button/Button";
import axios from "axios";
import config from "../../../config";
import {useHistory} from "react-router-dom"
import Cookies from "universal-cookie";
const cookies = new Cookies();
function EditHotel() {
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  var history = useHistory();
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setHotelEditImage(file);
    setHotelImage(URL.createObjectURL(file));
  };
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [description, setDescription] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [hotelImage, setHotelImage] = useState("");
  const [hotelEditImage, setHotelEditImage] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [contact, setContact] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const populateHotel = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/getHotelIndividualData`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        setHotelName(response.data.hotelData[0].name);
        setAddress(response.data.hotelData[0].address);
        setPincode(response.data.hotelData[0].pincode);
        setDescription(response.data.hotelData[0].description);
        setCheckIn(response.data.hotelData[0].checkIn);
        setCheckOut(response.data.hotelData[0].checkOut);
        setContact(response.data.hotelData[0].contact);
        setHotelImage(response.data.hotelData[0].media);
        setApiKey(response.data.hotelData[0].api_key);
        setActiveStatus(
          response.data.hotelData[0].active === "1" ? true : false
        );
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    populateHotel();
  }, []);
  const activeHotel = () => {
    setActiveStatus(!activeStatus);
  };

  const [isLoading, setIsLoading] = useState(false);
  const updateHotel = (e) => {
    setIsLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("hotelName", hotelName);
    fd.append("address", address);
    fd.append("pincode", pincode);
    fd.append("contact", contact);
    fd.append("description", description);
    fd.append("checkIn", checkIn);
    fd.append("checkOut", checkOut);
    {
      hotelEditImage && fd.append("hotelImage", hotelEditImage);
    }
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateHotelDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        setIsLoading(false)
        alert("Update Done Succesfully")
      })
      .catch(function (err) {
        console.log(err);
        setIsLoading(false)
      });
  };
  return (
    <>
    {user === "admin" ? <Container className="py-8" style={{ minHeight: "calc(100vh - 130px)" }}>
        <Row>
          <Col md={3}>
            <div>
              <div className="mb-3" onClick={() => handleImageClick()}>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  ref={fileUpload}
                  onChange={(e) => uploadImage(e)}
                />
                <img
                  src={hotelImage}
                  alt="hotel"
                  className="w-full h-52 object-contain p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                />
              </div>
              {/* <div className="flex">
                <span className="block mr-2">Deactive</span>
                <SwitchToogle
                  color="success"
                  checked={activeStatus}
                  onChange={() => activeHotel()}
                />
                <span className="block ml-2">Active</span>
              </div> */}
            </div>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={12}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Name
                  </label>
                  <input
                    className="nr_form_control"
                    type="text"
                    value={hotelName}
                    onChange={(e) => setHotelName(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="w-full mb-2">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Address
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "150px" }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                </div>
              </Col>
              <Col md={6}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Pincode
                  </label>
                  <input
                    className="nr_form_control"
                    type="tel"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Contact
                  </label>
                  <input
                    className="nr_form_control"
                    type="tel"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="w-full mb-2">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Description
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "150px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </Col>
              <Col md={6} className="">
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Check-in Time
                  </label>
                  <input
                    className="nr_form_control"
                    type="time"
                    value={checkIn}
                    onChange={(e) => setCheckIn(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6} className="">
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Check-out Time
                  </label>
                  <input
                    className="nr_form_control"
                    type="time"
                    value={checkOut}
                    onChange={(e) => setCheckOut(e.target.value)}
                  />
                </div>
              </Col>
              {/* <Row className="items-center w-full mx-0">
                <Col md={9} className="">
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Api Key
                    </label>
                    <input
                      className="nr_form_control"
                      type="text"
                      value={apiKey}
                      disabled
                      readOnly
                    />
                  </div>
                </Col>
                <Col md={3} className="">
                  <Button
                    className="w-full rounded py-2 px-2 text-white roboto"
                    type="button"
                    style={{ backgroundColor: "var(--primaryHotel)" }}
                  >
                    Generate Api Key
                  </Button>
                </Col>
              </Row> */}
              <Col md={2}>
                <Button
                  className="w-full rounded py-2 px-2 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={(e) => updateHotel(e)}
                >
                  {isLoading ? "Loading..." : "Update"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> : history.replace('/')}
    </>
  );
}

export default EditHotel;
