import React from "react";
import pl from "../../../assets/images/b1.png";
import { Link } from "react-router-dom";

function DashboardCard({ hotelId, title, view,icon,hotelData }) {
  return (
    <>
      {view === "admin" ? (
        <div className="relative p-3 rounded-sm mb-7 flex flex-col min-w-0 has-hover break-words bg-white bg-clip-border hover:shadow">
          <a href={`${hotelId}`} className="text-black no-underline">
            <figure>
              <img src={icon} alt="pl" className="h-40 w-full object-contain" />
              <figcaption className="block mt-3 text-sm">
                <h6 className="text-lg text-black mb-0">{title}</h6>
              </figcaption>
            </figure>
          </a>
        </div>
      ) : (
        <div className="relative p-3 rounded-sm mb-7 flex flex-col min-w-0 has-hover break-words bg-white bg-clip-border hover:shadow">
          <a href={`/hotel/${hotelData.id}`} className="text-black no-underline">
            <figure>
              <img src={hotelData.banner_image} alt="pl" className="h-40 w-full object-cover" />
              <figcaption className="block mt-3 text-sm">
                <h6 className="text-sm text-black mb-0">{hotelData.name}</h6>
                <p className="mb-0 text-muted lineClamp-2">
                  {hotelData.description}
                </p>
              </figcaption>
            </figure>
          </a>
        </div>
      )}
    </>
  );
}

export default DashboardCard;
