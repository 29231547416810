import React from "react";
import "./auth.css";
import Button from "../../components/forms/button/Button";
import Input from "../../components/forms/input/Input";

// Images
import logo from "../../assets/images/logo.png"

function ForgotPassword() {
  return (
    <React.Fragment>
      <div className="login absolute rounded-sm w-96 shadow-md bg-white">
        <div className="login__cardHeader text-center py-3 border-b border-gray-400">
          <div className="brand-wrapper flex justify-center items-center mb-3">
            <img src={logo} alt="logo" className="w-28" />
          </div>
          <h4 className="roboto font-medium text-gray-500">
            Please enter your information.
          </h4>
        </div>
        <div className="login__form px-3 py-3 border-b border-gray-400">
          <p className="pb-3 roboto text-gray-500 text-sm">
            Don't worry, we'll send you an email to reset your password.
          </p>
          <Input
            className="mb-3 px-2 py-2"
            placeholder="Your Email"
            type="email"
          />
          <Button
            className="w-full rounded py-2 px-2 text-white roboto"
            type="button"
            style={{ backgroundColor: "var(--primaryHotel)" }}
          >
            Reset Password
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
