import React from "react";
import "./modals.css";
import Modal from "react-bootstrap/Modal";

function Modals(props) {
  const { size } = props;
  return (
    <React.Fragment>
      <Modal
        {...props}
        size={`${size ? size : "sm"}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {props.children}
      </Modal>
    </React.Fragment>
  );
}

export default Modals;
