import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import PublishIcon from "@material-ui/icons/Publish";
import config from "../../config";
import ClearIcon from "@material-ui/icons/Clear";
import Cookies from "universal-cookie";

import {
  setHotelFeatureTitle,
  setHotelFeatureDescription,
  setHotelAmenImage,
  fetchImageLibrary,
  setModalAmenImage,
  setModalIconImage,
} from "../../services/actions";
import Button from "../forms/button/Button";
import Modals from "../modal/Modals";
import axios from "axios";
const cookies = new Cookies();
function FeatureRoom({
  position,
  globalReducer,
  amenData,
  setHotelFeatureTitles,
  setHotelFeatureDescriptions,
  id,
  fetchImageLibrarys,
  setModalAmenImages,
  setModalIconImages,
}) {
  var token = cookies.get("nw_token");
  var url = window.location.pathname;
  var idMain = url.substring(url.lastIndexOf("/") + 1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIconModalOpen, setIsIconModalOpen] = useState(false);
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e, index) => {
    var file = e.target.files[0];
    addImage(file);
  };
  const openImageGallery = () => {
    setIsModalOpen(true);
  };
  const openIconGallery = () => {
    setIsIconModalOpen(true);
  };
  // onClick={handleImageClick}
  const addImage = (file) => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("action", "ADD");
    fd.append("hotelImage", file);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getHotelMedia = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getMediaLibrary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchImageLibrarys(response.data.mediaList);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const setImage = (e, mediaData, componentid) => {
    var el = document.querySelectorAll(".el");
    var parentElem = e.target.parentNode;
    el.forEach((elm) => {
      elm.classList.remove("activeImageCheck");
    });
    parentElem.classList.add("activeImageCheck");
    setModalAmenImages(mediaData, componentid);
  };
  const setIconImage = (e, mediaData, componentid) => {
    var el = document.querySelectorAll(".iconEl");
    var parentElem = e.target.parentNode;
    el.forEach((elm) => {
      elm.classList.remove("activeImageCheck");
    });
    parentElem.classList.add("activeImageCheck");
    setModalIconImages(mediaData, componentid);
  };
  const deleteImage = (e, mediaId) => {
    e.preventDefault();
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("action", "DEL");
    fd.append("mediaId", mediaId);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      {position === "right" ? (
        <Row className="border border-gray-300 px-3 py-4 rounded mb-4">
          <Col md={6}>
            <div>
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Image
              </label>
              <img
                src={amenData.media}
                onClick={() => openImageGallery()}
                alt="hotel"
                className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
              />
            </div>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={3} className="mb-4">
                <div>
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Icon
                  </label>
                  <img
                    src={amenData.icon}
                    onClick={() => openIconGallery()}
                    alt="hotel"
                    className="w-full object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                  />
                </div>
              </Col>
              <Col md={9}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Hotel Name
                  </label>
                  <input
                    className="nr_form_control"
                    type="text"
                    value={amenData.title}
                    onChange={(e) => setHotelFeatureTitles(e.target.value, id)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Description
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "190px" }}
                    value={amenData.content}
                    onChange={(e) =>
                      setHotelFeatureDescriptions(e.target.value, id)
                    }
                  ></textarea>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="border border-gray-300 px-3 py-4 rounded mb-4">
          <Col md={6}>
            <Row>
              <Col md={3} className="mb-4">
                <div>
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Icon
                  </label>
                  <img
                    src={amenData.icon}
                    onClick={() => openIconGallery()}
                    alt="hotel"
                    className="w-full object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                  />
                </div>
              </Col>
              <Col md={9}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Hotel Name
                  </label>
                  <input
                    className="nr_form_control"
                    type="text"
                    value={amenData.title}
                    onChange={(e) => setHotelFeatureTitles(e.target.value, id)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Description
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "190px" }}
                    value={amenData.content}
                    onChange={(e) =>
                      setHotelFeatureDescriptions(e.target.value, id)
                    }
                  ></textarea>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <div>
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Image
              </label>
              <img
                src={amenData.media}
                onClick={() => openImageGallery()}
                alt="hotel"
                className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
              />
            </div>
          </Col>
        </Row>
      )}
      <Modals
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="py-2 border-b border-gray-300">
            <Col md={12}>
              <div className="">
                <h3 className="mb-0">Add Image</h3>
              </div>
            </Col>
          </Row>
          <Row className="py-2 px-2 max-h-96 overflow-y-scroll">
            <Col md={3}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <div
                onClick={handleImageClick}
                className="h-40 flex flex-col cursor-pointer rounded hover:shadow border-2 items-center justify-center text-center text-gray-400 border-dashed border-gray-300"
              >
                <PublishIcon />
                click To Upload
              </div>
            </Col>
            {globalReducer.mediaLibrary.map((mediaData, idx) => {
              return (
                <Col md={3} key={idx} className="mb-2 px-1">
                  <div
                    className="cursor-pointer h-40 relative z-50 el hasDeleteHover overflow-hidden"
                    data-id={mediaData.mediaId}
                  >
                    <ClearIcon className={""} style={{ display: "none" }} />
                    <img
                      src={mediaData.media}
                      alt="img"
                      className="object-cover h-full w-full"
                      onClick={(e) => setImage(e, mediaData, id)}
                    />
                    <button
                      style={{ backgroundColor: "#fbb13c" }}
                      className="absolute font-san py-1 text-sm deleteModalImage w-full"
                      onClick={(e) => deleteImage(e, mediaData.mediaId)}
                    >
                      Delete
                    </button>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="py-2 border-t border-gray-300">
            <Col md={12}>
              <div className=" text-right">
                <Button
                  className="rounded py-2 px-3 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => setIsModalOpen(false)}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modals>

      <Modals
        show={isIconModalOpen}
        onHide={() => setIsIconModalOpen(false)}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="py-2 border-b border-gray-300">
            <Col md={12}>
              <div className="">
                <h3 className="mb-0">Add Icon</h3>
              </div>
            </Col>
          </Row>
          <Row className="py-2 px-2 max-h-96 overflow-y-scroll">
            {globalReducer.iconsLibrary.map((mediaData, idx) => {
              return (
                <Col md={3} key={idx} className="mb-2 px-1">
                  <div
                    className="cursor-pointer h-40 border rounder flex items-center justify-center iconEl"
                    onClick={(e) => setIconImage(e, mediaData, id)}
                  >
                    <ClearIcon className={""} style={{ display: "none" }} />
                    <img
                      src={mediaData.media}
                      alt="img"
                      className="object-contain h-full w-24"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="py-2 border-t border-gray-300">
            <Col md={12}>
              <div className=" text-right">
                <Button
                  className="rounded py-2 px-3 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => setIsIconModalOpen(false)}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modals>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setHotelFeatureTitles: (value, id) => {
      dispatch(setHotelFeatureTitle(value, id));
    },
    setHotelFeatureDescriptions: (value, id) => {
      dispatch(setHotelFeatureDescription(value, id));
    },
    setHotelAmenImages: (value, id) => {
      dispatch(setHotelAmenImage(value, id));
    },
    fetchImageLibrarys: (value) => {
      dispatch(fetchImageLibrary(value));
    },
    setModalAmenImages: (value, id) => {
      dispatch(setModalAmenImage(value, id));
    },
    setModalIconImages: (value, id) => {
      dispatch(setModalIconImage(value, id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(FeatureRoom);
