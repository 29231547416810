import React, { useState, useEffect } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { PiKeyReturn } from "react-icons/pi";
import "./listinput.css";
function ListInput({ label, list,listData,className }) {
  const [value, setvalue] = useState("");
  const [data, setData] = useState([...listData]);

  const getdata = (e) => {
    setvalue(e.target.value);
  };
  const getValue = (e) => {
    if (e.key === "Enter") {
      let finalData = [...data,value]
      setData(finalData);
      setvalue("");
      list(finalData);
    }
  };
  const removeItem = (item) => {
    const index = data.indexOf(item)
    data.splice(index,1);
    setData([...data])
  }
  return (
    <>
      <div className={`${className}`}>
        <div className="w-full mb-4">
          <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
            {label}
          </label>
          {data.length > 0
            ? data.map((list, idx) => {
                return (
                  <div className={`featList bg-gray-200 mb-2`} key={idx}>
                    <span className={`f-1`}>{list}</span>
                    <ClearIcon className={`cursor-pointer`} onClick={() => removeItem(list)}/>
                  </div>
                );
              })
            : ""}
          <input
            className="nr_form_control"
            type="text"
            value={value}
            onChange={(e) => {
              getdata(e);
            }}
            onKeyPress={(e) => getValue(e)}
          />
          <p className=" text-xs text-gray-500 flex items-center space-x-3 mt-2">Press <span className="mx-2 flex items-center px-2 py-2 rounded-md bg-gray-100 space-x-3">Enter/Return <PiKeyReturn className="ml-1 w-4 h-4" /></span> to add the feature</p>
        </div>
      </div>
    </>
  );
}

export default ListInput;
