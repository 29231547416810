import React, { useState } from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import onClickOutside from "react-onclickoutside";
import { useHistory } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom"
import Cookies from "universal-cookie";
import users from "../../assets/images/user-picture.png";
const cookies = new Cookies();
function Header({ user }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const history = useHistory();
  const openSignout = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  Header.handleClickOutside = () => setIsPopupOpen(false);
  const logout = () => {
    cookies.remove("nw_token", { path: "/" })
    cookies.remove("nw_post", { path: "/" })
    history.replace("/");
  };
  return (
    <>
      <header
        className="h-16 sticky top-0 z-40"
        style={{ backgroundColor: "var(--primaryHotel)" }}
      >
        <div className="max-1400 h-full">
          <div className="flex h-full items-center">
            <div className="flex-50"></div>
            <div className="user--Profile flex-50 flex justify-end relative items-center">
              <div className="mr-3">
                <Tooltip title="Dashboard">
                  <Link to={`${user === "Admin" ? '/dashboard' : '/hotel-dashboard'}`}>
                  <DashboardIcon
                    className="text-white cursor-pointer"
                    style={{ fontSize: "40px" }}
                  />
                  </Link>
                </Tooltip>
              </div>
              <div className="mr-3">
                <span className="text-white font-sans font-semibold">
                  Hello {user}
                </span>
              </div>
              <div className="relative">
                <div
                  onClick={() => openSignout()}
                  className="relative w-10  h-10 rounded-full overflow-hidden border border-white border-solid cursor-pointer"
                >
                  <img
                    src={users}
                    alt="user"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div
                  className={`absolute w-36 bg-white p-1 -bottom-14 right-0 z-20 shadow rounded ${
                    isPopupOpen ? "" : "hidden"
                  }`}
                >
                  <div className="pointer w-5 h-5 bg-white right-2 -top-2 transform rotate-45 absolute rounded-sm"></div>
                  <div
                    className="cursor-pointer hover:bg-gray-200 rounded p-1"
                    onClick={() => logout()}
                  >
                    <ExitToAppIcon className="mr-2" />
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
const clickOutsideConfig = {
  handleClickOutside: () => Header.handleClickOutside,
};

export default onClickOutside(Header, clickOutsideConfig);
