import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import HotelDetails from "../../../components/hotelDetails/HotelDetails";
import HotelPage from "../../../components/hotelPage/HotelPage";
import RoomDetails from "../../../components/RoomDetails/RoomDetails";
import config from "../../../config";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"
import {
  getRoomDetail,
  getHotelPageDetail,
  getHotelDetail,
  fetchImageLibrary,
  fetchIconLibrary
} from "../../../services/actions";
import b1 from "../../../assets/images/b1.png"
import Cookies from "universal-cookie";
import FadeLoader from "../../../components/loader/FadeLoader";
const cookies = new Cookies();
function Hotel({ getRoomDetailData, setHotelPageDetail, setHotelDetail, fetchImageLibrarys, fetchIconLibrarys }) {
  var url = window.location.pathname;
  var urlHotelID = url.substring(url.lastIndexOf("/") + 1);
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  const [isHotelDetailLoading, setIsHotelDetailLoading] = useState(false)
  const [isHotelPageLoading, setIsHotelPageLoading] = useState(false)
  const [isHotelRoomDetailLoading, setIsHotelRoomDetailLoading] = useState(false)
  const [isHotelMediaLoading, setIsHotelMediaLoading] = useState(false)
  const [isIconLoading, setIsIconLoading] = useState(false)
  var history = useHistory();
  const getHotelDetail = () => {
    setIsHotelDetailLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", urlHotelID);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getHotelDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        getRoomDetailData(response.data.hotelDetailsData);
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setIsHotelDetailLoading(false)
      })
  };
  const getHotelPage = () => {
    setIsHotelPageLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", urlHotelID);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getHotelPage`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        setHotelPageDetail(response.data.hotelPageLayoutData);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.response)
      })
      .finally(() => {
        setIsHotelPageLoading(false)
      })
  };
  const getHotelRoomDetail = () => {
    setIsHotelRoomDetailLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", urlHotelID);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getHotelRoomDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        setHotelDetail(response.data.hotelRoomDetailsData);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.response)
      })
      .finally(() => {
        setIsHotelRoomDetailLoading(false)
      })
  };
  const getHotelMedia = () => {
    setIsHotelMediaLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", urlHotelID);
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getMediaLibrary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchImageLibrarys(response.data.mediaList)
      })
      .catch(function (err) {
        console.log(err);
        alert(err.response.data.message)
      })
      .finally(() => {
        setIsHotelMediaLoading(false)
      })
  };
  const getIcon = () => {
    setIsIconLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getIconPack`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchIconLibrarys(response.data.mediaList)
      })
      .catch(function (err) {
        console.log(err);
        alert(err.response.data.message)
      })
      .finally(() => {
        setIsIconLoading(false)
      })
  };
  useEffect(() => {
    {
      token && getHotelDetail();
      getHotelPage();
      getHotelRoomDetail();
      getHotelMedia();
      getIcon();
    }

  }, []);
  return (
    <>
      <div style={{
        minHeight: 'calc(100vh - 110px)'
      }}>
        {user === "manager" ? <div className="container">
          <Row className="mt-7 mb-10">
            {(isHotelDetailLoading || isHotelPageLoading || isHotelRoomDetailLoading || isHotelMediaLoading || isIconLoading)
              ? <FadeLoader />
              : <Col md={12}>
                <Tabs>
                  <TabList className="hotel__tab flex flex-wrap pl-0 mb-0 list-none">
                    <Tab
                      className=" flex-grow flex-shrink text-center cursor-pointer bg-gray-300 py-3 px-12 mr-1 text-sm hotel-tab-b-color border rounded-t"
                      style={{ marginBottom: "-1px", borderBottom: "transparent" }}
                    >
                      Hotel Details
                    </Tab>
                    <Tab
                      className=" flex-grow flex-shrink text-center cursor-pointer bg-gray-300 py-3 px-12 mr-1 text-sm hotel-tab-b-color border rounded-t"
                      style={{ marginBottom: "-1px", borderBottom: "transparent" }}
                    >
                      Hotel Page
                    </Tab>
                    <Tab
                      className=" flex-grow flex-shrink text-center cursor-pointer bg-gray-300 py-3 px-12 text-sm hotel-tab-b-color border rounded-t"
                      style={{ marginBottom: "-1px", borderBottom: "transparent" }}
                    >
                      Room Details
                    </Tab>
                  </TabList>
                  <div
                    className="bg-white p-7 border border-solid rounded-t-none border-gray-300"
                    style={{ borderTopLeftRadius: "0" }}
                  >
                    <TabPanel>
                      <HotelDetails />
                    </TabPanel>
                    <TabPanel>
                      <HotelPage />
                    </TabPanel>
                    <TabPanel>
                      <RoomDetails />
                    </TabPanel>
                  </div>
                </Tabs>
              </Col>}
          </Row>
        </div> : history.replace('/')}
      </div>

    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    getRoomDetailData: (value) => {
      dispatch(getRoomDetail(value));
    },
    setHotelPageDetail: (value) => {
      dispatch(getHotelPageDetail(value));
    },
    setHotelDetail: (value) => {
      dispatch(getHotelDetail(value));
    },
    fetchImageLibrarys: (value) => {
      dispatch(fetchImageLibrary(value));
    },
    fetchIconLibrarys: (value) => {
      dispatch(fetchIconLibrary(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(Hotel);
