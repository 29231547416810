import React, { useState } from "react";
import "./auth.css";
import Button from "../../components/forms/button/Button";
import Input from "../../components/forms/input/Input";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import Cookies from "universal-cookie";

// Images
import logo from "../../assets/images/logo.png";
import eye from "../../assets/images/eye.png";
import noteye from "../../assets/images/noteye.png";
const cookies = new Cookies();
function Login() {
  var post = cookies.get("nw_post");
  const [isLoading,setIsLoading] = useState(false)
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const login = () => {
    setIsLoading(true)
    var fd = new FormData();
    fd.append("email", username);
    fd.append("password", password);
    axios({
      method: "POST",
      url: `${config.base_url}Authentication/login`,
      data: fd,
    })
      .then(function (response) {
        // console.log("response", response);
        if (response.data.message === "Login Success") {
          cookies.set("nw_token", response.data.token, { path: "/" });
          cookies.set("nw_post", response.data.role, { path: "/" });
          if (response.data.role === "manager") {
            history.replace("/hotel-dashboard");
          }
          if (response.data.role === "admin") {
            history.replace("/dashboard");
          }
        }
      })
      .catch(function (err) {
        alert(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };
  const enter = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };
  const whereToRedirect = () => {
    if (post === "manager") {
      history.replace("/hotel-dashboard");
    }
    if (post === "admin") {
      history.replace("/dashboard");
    }
  };
  const [isShowPassword, setIsShowPassword] = useState(false);
  return (
    <React.Fragment>
      {post ? (
        whereToRedirect()
      ) : (
        <div className="login absolute rounded-sm w-96 shadow-md bg-white">
          <div className="login__cardHeader text-center py-3 border-b border-gray-400">
            <div className="brand-wrapper flex justify-center items-center mb-3">
              <img src={logo} alt="logo" className="w-28" />
            </div>
            <h4 className="roboto font-medium text-gray-500">
              Please enter your information.
            </h4>
          </div>
          <div className="login__form px-3 py-3 border-b border-gray-400">
            <Input
              className="mb-3 px-2 py-2"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              type="email"
            />
            <div className="relative">
              <Input
                className="mb-3 px-2 py-2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => enter(e)}
                placeholder="Password"
                type={isShowPassword ? "text" : "password"}
              />
              <img
                src={isShowPassword ? eye : noteye}
                alt="eye"
                className="passwordEye w-6 absolute right-4 top-2 cursor-pointer"
                onClick={() => setIsShowPassword(!isShowPassword)}
              />
            </div>
            <Button
              onClick={() => login()}
              className="w-full rounded py-2 px-2 text-white roboto"
              disabled={isLoading}
              type="button"
              style={{ backgroundColor: "var(--primaryHotel)" }}
            >
              {isLoading 
              ? 'Sining in....' 
              : 'Sign in'}
            </Button>
          </div>
          <div className="login__cardFooter px-3 py-3">
            <Link
              to="/forgot-password"
              className="roboto font-medium text-gray-500 text-sm"
            >
              Forgot Password
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Login;
