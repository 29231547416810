import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../components/forms/button/Button";
import axios from "axios";
import config from "../../../config";
import eye from "../../../assets/images/eye.png";
import noteye from "../../../assets/images/noteye.png";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { checkIfEmpty } from "../../../utils/utils";
import toast from "react-hot-toast";
const cookies = new Cookies();
function CreateUser({ globalReducer }) {
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  const history = useHistory();
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setManagerImage(file);
    setBlobManagerImage(URL.createObjectURL(file));
  };
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("Test$1234");
  const [contact, setContact] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [managerImage, setManagerImage] = useState("");
  const [blobManagerImage, setBlobManagerImage] = useState(user);
  const createUser = () => {
    if (validateFields()) {
      setIsLoading(true);
      var fd = new FormData();
      fd.append("token", JSON.stringify(token));
      fd.append("identity", "norwoodinns");
      fd.append("firstName", fname);
      fd.append("lastName", lname);
      fd.append("email", email);
      fd.append("phone", contact);
      fd.append("password", password);
      fd.append("profilePhoto", managerImage);
      axios({
        method: "POST",
        url: `${config.base_url}Hoteladmin/createUser`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: fd,
      })
        .then(function (response) {
          setIsLoading(false);
          alert("User created");
          history.push("/manageuser");
        })
        .catch(function (err) {
          setIsLoading(false);
          console.log(err.response.data);
        });
    }
  };
  const [isShowPassword, setIsShowPassword] = useState(false);
  const passwordShow = () => {
    setIsShowPassword(!isShowPassword);
  };

  const validateFields = () => {
    let isValid = true;

    if (checkIfEmpty(fname)) {
      isValid = false;
      toast.error("Please enter first name");
    }

    if (checkIfEmpty(lname)) {
      isValid = false;
      toast.error("Please enter last name");
    }

    if (checkIfEmpty(email)) {
      isValid = false;
      toast.error("Please enter email");
    }

    if (checkIfEmpty(contact)) {
      isValid = false;
      toast.error("Please enter contact");
    }

    if (checkIfEmpty(managerImage)) {
      isValid = false;
      toast.error("Please add manager image");
    }

    return isValid;
  };

  const asignManager = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelList", JSON.stringify(globalReducer.hotelAssignedIds));
    fd.append("hotelManager", id);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/assignHotelManager`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      {user === "admin" ? (
        <Container
          className="py-8"
          style={{ minHeight: "calc(100vh - 130px)" }}
        >
          <Row>
            <Col md={3}>
              <div>
                <div className="mb-3" onClick={() => handleImageClick()}>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    ref={fileUpload}
                    onChange={(e) => uploadImage(e)}
                  />
                  <img
                    src={blobManagerImage}
                    alt="Add manager image"
                    className="w-full h-52 object-contain p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                  />
                </div>
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={6}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      First Name
                    </label>
                    <input
                      className="nr_form_control"
                      type="text"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Last Name
                    </label>
                    <input
                      className="nr_form_control"
                      type="text"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Email
                    </label>
                    <input
                      className="nr_form_control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Contact
                    </label>
                    <input
                      className="nr_form_control"
                      type="tel"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Password
                    </label>
                    <div className="flex relative">
                      <input
                        className="nr_form_control"
                        type={isShowPassword ? "text" : "password"}
                        defaultValue={password}
                        readOnly
                      />
                      <div>
                        <img
                          src={isShowPassword ? eye : noteye}
                          alt="eye"
                          className="passwordEye w-6 absolute right-4 top-1 cursor-pointer"
                          onClick={() => passwordShow()}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={2}>
                  <Button
                    className="w-full rounded py-2 px-2 text-white roboto"
                    type="button"
                    style={{ backgroundColor: "var(--primaryHotel)" }}
                    onClick={() => {
                      createUser();
                      asignManager();
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading...." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        history.push("/")
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps, null)(CreateUser);
