import { useRef } from "react";
import { Row, Col } from "react-bootstrap";
// import pl from "../../../assets/images/banner.jpg";
import Button from "../../../components/forms/button/Button";
import { connect } from "react-redux";
import {setExtraDescription,setExtraImage} from "../../../services/actions"
import axios from "axios"
import config from "../../../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function HotelExtras({landingPageData,setExtraDescriptions,setExtraImages}) {
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  var token = cookies.get("nw_token");
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setExtraImages(file)
  };
  const saveLandingExtra = () =>{
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("id", id);
    fd.append("component", "fullwidth_container");
    fd.append("description", landingPageData.hotelExtraDescription);
    fd.append("media", landingPageData.hotelExtraImgFile);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateLandingPageComponent`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    }).then(function(response){
      console.log(response)
      if(response.data.message === "Component Updated"){
        alert("Page Updated")
      }
    }).catch(function(err){
      console.log(err)
      alert(err.response.data.message)
    })

  }
  return (
    <>
      <Row>
        <Col md={12}>
          <Col md={12} className="mb-4 px-0">
            <div className="w-full" onClick={handleImageClick}>
            <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <img
                src={landingPageData.hotelExtraImg}
                alt="hotel"
                className="w-full h-96 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
              />
            </div>
          </Col>
          <Col md={12} className="px-0">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Description
              </label>
              <textarea
                className="nr_form_control"
                style={{ height: "170px" }}
                value={landingPageData.hotelExtraDescription}
                onChange={(e) => setExtraDescriptions(e.target.value)}
              ></textarea>
            </div>
          </Col>
          <Col md={12} className="px-0">
            <Button
              className="rounded py-2 px-10 text-white roboto"
              type="button"
              style={{ backgroundColor: "var(--primaryHotel)" }}
              onClick={() => saveLandingExtra()}
            >
              Save
            </Button>
          </Col>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setExtraDescriptions: (value) => {
      dispatch(setExtraDescription(value));
    },
    setExtraImages: (value) => {
      dispatch(setExtraImage(value));
    },
  };
};
export default connect(mapStateToProps,mapDispatchToState)(HotelExtras);
