import {combineReducers} from "redux"
import landingPageData from "./landingpage"
import roomDetailData from "./roomdetails"
import hotelPageData from "./hotelPage"
import hotelDetailData from "./hotelDetail"
import globalReducer from "./globalReducer"

export default combineReducers({
    landingPageData,
    roomDetailData,
    hotelPageData,
    hotelDetailData,
    globalReducer
})
