import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Button from "../../components/forms/button/Button";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { throwError } from "../../errorHandling";
const cookies = new Cookies();
function ManagerUser() {
  var user = cookies.get("nw_post");
  var token = cookies.get("nw_token");
  const history = useHistory();
  const createUser = () => {
    window.location.assign("/createuser");
  };
  const [userList, setUserList] = useState([]);
  const [selectedActive, setSelectedActive] = useState("");
  const getUserList = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("pageNo", 0);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/getUserList`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        // console.log(response);
        setUserList(response.data.userDetails);
      })
      .catch(function (err) {
        // console.log(err);
        throwError(err);
      });
  };
  useEffect(() => {
    getUserList();
  }, []);
  const userStatus = (value, userId) => {
    setSelectedActive(value);
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("mangerId", userId);
    fd.append("activeStatus", value);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateUserActiveStatus`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        toast.success("Manager Status Updated");
        getUserList();
      })
      .catch(function (err) {
        // console.log(err);
        throwError(err);
      });
  };
  return (
    <>
      {user === "admin" ? (
        <Container
          className="py-8"
          style={{ minHeight: "calc(100vh - 130px)" }}
        >
          <Row>
            <Col md={12} style={{ textAlign: "-webkit-right" }}>
              <div className="w-48 mb-4">
                <Button
                  className="w-full rounded py-3 px-2 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => createUser()}
                >
                  Create user
                </Button>
              </div>
            </Col>
            <Col md={12}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="managerHotelBody">
                  {userList.map((userList, idx) => {
                    return (
                      <tr key={idx + "_" + userList.id}>
                        <td>{idx + 1}</td>
                        <td>{userList.fullName}</td>
                        <td>{userList.email}</td>
                        <td>
                          <div className="">
                            <select
                              className="w-full p-1 rounded"
                              onChange={(e) =>
                                userStatus(e.target.value, userList.id)
                              }
                            >
                              <option value="">---select----</option>
                              <option
                                value="1"
                                selected={
                                  userList.activeStatus === "1" ? true : false
                                }
                              >
                                Active
                              </option>
                              <option
                                value="0"
                                selected={
                                  userList.activeStatus === "0" ? true : false
                                }
                              >
                                In-active
                              </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          {userList.activeStatus === "1" ? (
                            <a href={`/edituser/${userList.id}`}>
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </a>
                          ) : (
                            <p>Manager is In-active</p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      ) : (
        history.push("/")
      )}
    </>
  );
}

export default ManagerUser;
