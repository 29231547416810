import { SET_IMAGE_LIBRARY, SET_ICON_LIBRARY, SET_HOTEL_TO_MANAGER, ROMOVE_HOTEL_ASSIGNED, SET_HOTEL_ASSIGN } from "../constant";
const initialFormsData = {
  mediaLibrary: "",
  iconsLibrary: "",
  hotelAssigned: [],
  hotelAssignedIds: [],
};

const globalReducer = (state = initialFormsData, action) => {
  switch (action.type) {
    case SET_IMAGE_LIBRARY: {
      state.mediaLibrary = action.payload.reverse()
      return {
        ...state,
      };
    }
    case SET_ICON_LIBRARY: {
      state.iconsLibrary = action.payload.reverse()
      return {
        ...state,
      };
    }
    case SET_HOTEL_ASSIGN: {
      // state.iconsLibrary = action.payload.reverse()        
      if (action.payload !== null) {
        state.hotelAssigned = action.payload
        action.payload.map((hotelDatas) => {
          state.hotelAssignedIds.push(hotelDatas.hotelId)
        })
      }
      return {
        ...state,
      };
    }
    case SET_HOTEL_TO_MANAGER: {
      if (state.hotelAssignedIds.includes(action.payload.hotelId)) {
        alert("Hotel Already Assigned")
      } else {
        state.hotelAssigned.push(action.payload)
        state.hotelAssignedIds.push(action.payload.hotelId)
      }
      return {
        ...state,
      };
    }
    case ROMOVE_HOTEL_ASSIGNED: {
      var indexInHotelAssignedIds = state.hotelAssignedIds.indexOf(action.payload)
      console.log(indexInHotelAssignedIds)
      state.hotelAssigned.splice(indexInHotelAssignedIds, 1)
      state.hotelAssignedIds.splice(indexInHotelAssignedIds, 1)
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default globalReducer;
