import React, { useState } from "react";
import "./listinput.css";
import HotelSerch from "./HotelSerch";
import { connect } from "react-redux";
import {searchHotelList} from "../../../apis"
import {setHotelToManager} from "../../../services/actions"
import HotelResult from "./HotelResult";
function ListinputSearch({ className,globalReducer }) {
  const [value, setvalue] = useState("");
  const [isSearch, setIsSearch] = useState(false)
  const [searchResult, setSearchResult] = useState([])
  const getdata = (e) => {
    setvalue(e.target.value);
    setIsSearch(true)
    searchHotelList(e.target.value).then(function(res){
      setSearchResult(res.hotelDetails)
    })
  };
  const getValue = (e) => {
    if (e.key === "Enter") {
      setvalue("");
    }
  };
  
  return (
    <>
      <div className={`${className}`}>
        <div className="w-full mb-4">
          <div className="">
            {globalReducer.hotelAssigned?.map((data,idx) => {
              return <HotelResult hotelAssigned={true} listData={data} key={idx}/>
            })}
          </div>
          <input
            className="nr_form_control"
            type="text"
            placeholder="Search hotel"
            value={value}
            onChange={(e) => {
              getdata(e);
            }}
            onKeyPress={(e) => getValue(e)}
          />
          {isSearch ? <HotelSerch setvalue={() => setvalue("")} close={() => setIsSearch(false)} hotelResult={searchResult}/> : ""}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
      setHotelToManagers: (value) => {
      dispatch(setHotelToManager(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(ListinputSearch);
