import React from "react";
import { Container } from "react-bootstrap";

const HotelsListing = () => {
  const hotels = [
    {
      name: "Hotel Lorem Ipsum",
      status: true,
    },
    {
      name: "Hotel Lorem Ipsum 2",
      status: true,
    },
    {
      name: "Hotel Lorem 3",
      status: true,
    },
    {
      name: "Hotel Lorem 4",
      status: true,
    },
    {
      name: "Hotel Lorem Ipsum 5",
      status: true,
    },
    {
      name: "Hotel Lorem Ipsum Dolor 6",
      status: true,
    },
  ];

  return (
    <div>
      <Container className="py-4">
        <p className="text-xl font-semibold">List of hotels</p>
        <table className="w-full">
          <tr className=" font-semibold border-2 bg-gray-100">
            <td className="px-3 py-2 text-center">Hotel Name</td>
            <td className="px-3 py-2  text-center">Active status</td>
          </tr>

          {hotels.map((el) => {
            return (
              <tr className="bg-slate-400 border bg-white">
                <td className="px-3 py-2">Hotel Lorem Ipsum</td>
                <td className="px-3 py-3  h-full grid place-items-center">
                  <input type="checkbox" name="" id="" />
                </td>
              </tr>
            );
          })}
        </table>
      </Container>
    </div>
  );
};

export default HotelsListing;
