import React from "react";

function Input({ className, inputClass, onKeyPress,onChange, value, placeholder,type }) {
  return (
    <React.Fragment>
      <div className={`${className} border border-gray-300 rounded`}>
        <input
          className={`w-full h-full`}
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </React.Fragment>
  );
}

export default Input;
