import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import user from "../../../assets/images/sleep.jpeg";
import Button from "../../../components/forms/button/Button";
// import SwitchToogle from "../../../components/forms/switchToogle/SwitchToogle";
import config from "../../../config";
import {useHistory} from "react-router-dom"
import Cookies from "universal-cookie";
const cookies = new Cookies();
function CreateHotel() {
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  const history = useHistory();
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setHotelImage(file);
    setBlobHotelImage(URL.createObjectURL(file))
  };
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [description, setDescription] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [hotelImage, setHotelImage] = useState("");
  const [contact, setContact] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [blobHotelImage, setBlobHotelImage] = useState(user);
  const createHotel = () => {
    setIsLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelName", hotelName);
    fd.append("address", address);
    fd.append("pincode", pincode);
    fd.append("description", description);
    fd.append("checkIn", checkIn);
    fd.append("checkOut", checkOut);
    fd.append("hotelImage", hotelImage);
    fd.append("contact", contact);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/createHotel`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        setIsLoading(false)
        alert("hotelCreated")
        history.push('/managehotel')
      })
      .catch(function (err) {
        setIsLoading(false)
        console.log(err);
      });
  };
  return (
    <>
    {user === "admin" ? <Container className="py-8" style={{ minHeight: "calc(100vh - 130px)" }}>
        <Row>
          <Col md={3}>
            <div>
              <div className="mb-3" onClick={() => handleImageClick()}>
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  ref={fileUpload}
                  onChange={(e) => uploadImage(e)}
                />
                <img
                  src={blobHotelImage}
                  alt="hotel"
                  className="w-full h-52 object-contain p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                />
              </div>
            </div>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={12}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Name
                  </label>
                  <input
                    className="nr_form_control"
                    type="text"
                    value={hotelName}
                    onChange={(e) => setHotelName(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="w-full mb-2">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Address
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "150px" }}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                </div>
              </Col>
              <Col md={6}>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Pincode
                  </label>
                  <input
                    className="nr_form_control"
                    type="tel"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Contact
                  </label>
                  <input
                    className="nr_form_control"
                    type="tel"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="w-full mb-2">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Description
                  </label>
                  <textarea
                    className="nr_form_control"
                    style={{ height: "150px" }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </Col>
              <Col md={6} className="">
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Check-in Time
                  </label>
                  <input
                    className="nr_form_control"
                    type="time"
                    value={checkIn}
                    onChange={(e) => setCheckIn(e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6} className="">
                <div className="w-full mb-4">
                  <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                    Check-out Time
                  </label>
                  <input
                    className="nr_form_control"
                    type="time"
                    value={checkOut}
                    onChange={(e) => setCheckOut(e.target.value)}
                  />
                </div>
              </Col>
              <Row className="items-center w-full mx-0">
                <Col md={9} className="">
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Api Key
                    </label>
                    <input className="nr_form_control" type="text" />
                  </div>
                </Col>
                <Col md={3} className="">
                  <Button
                    className="w-full rounded py-2 px-2 text-white roboto"
                    type="button"
                    style={{ backgroundColor: "var(--primaryHotel)" }}
                  >
                    Generate Api Key
                  </Button>
                </Col>
              </Row>
              <Col md={2}>
                <Button
                  className="w-full rounded py-2 px-2 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                  onClick={() => createHotel()}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading...." : "Save"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container> : history.push('/')}
    </>
  );
}

export default CreateHotel;
