import React, { useRef, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import Button from "../forms/button/Button";
import PublishIcon from "@material-ui/icons/Publish";
import ClearIcon from "@material-ui/icons/Clear";
import {
  setHotelAddonTitle,
  setHotelAddonDescription,
  setHotelAddonImage,
  fetchImageLibrary,
  setModalAddonImage
} from "../../services/actions";
import Modals from "../modal/Modals";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function HotelFeature({
  addOnData,
  globalReducer,
  id,
  setHotelAddonTitles,
  setHotelAddonDescriptions,
  setHotelAddonImages,
  fetchImageLibrarys,
  setModalAddonImages
}) {
  var token = cookies.get("nw_token");
  var url = window.location.pathname;
  var idMain = url.substring(url.lastIndexOf("/") + 1);
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    addImage(file);
  };
  const addImage = (file) => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("action", "ADD");
    fd.append("hotelImage", file);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getHotelMedia = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getMediaLibrary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchImageLibrarys(response.data.mediaList);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openImageGallery = () => {
    setIsModalOpen(true);
  };
  const setImage = (e, mediaData, componentid) => {
    var el = document.querySelectorAll(".el");
    var parentElem = e.target.parentNode;
    el.forEach((elm) => {
      elm.classList.remove("activeImageCheck");
    });
    parentElem.classList.add("activeImageCheck");
    setModalAddonImages(mediaData, componentid);
  };
  const deleteImage = (e, mediaId) => {
    e.preventDefault()
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", idMain);
    fd.append("action", "DEL");
    fd.append("mediaId", mediaId);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      <Col md={4} className="mb-4">
        <div className="mb-2" onClick={() => openImageGallery()}>
          <img
            src={addOnData.media}
            alt="hotel"
            className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
          />
        </div>
        <div className="w-full mb-2">
          <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
            Title
          </label>
          <input
            className="nr_form_control"
            type="text"
            value={addOnData.title}
            onChange={(e) => setHotelAddonTitles(e.target.value, id)}
          />
        </div>
        <div className="w-full mb-2">
          <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
            Description
          </label>
          <textarea
            className="nr_form_control"
            style={{ height: "150px" }}
            value={addOnData.content}
            onChange={(e) => setHotelAddonDescriptions(e.target.value, id)}
          ></textarea>
        </div>
        {/* <SwitchToogle color="success" /> */}
      </Col>
      <Modals
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="py-2 border-b border-gray-300">
            <Col md={12}>
              <div className="">
                <h3 className="mb-0">Add Image</h3>
              </div>
            </Col>
          </Row>
          <Row className="py-2 px-2 max-h-96 overflow-y-scroll">
            <Col md={3}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <div
                onClick={handleImageClick}
                className="h-40 flex flex-col cursor-pointer rounded hover:shadow border-2 items-center justify-center text-center text-gray-400 border-dashed border-gray-300"
              >
                <PublishIcon />
                click To Upload
              </div>
            </Col>
            {globalReducer.mediaLibrary.map((mediaData, idx) => {
              return (
                <Col md={3} key={idx} className="mb-2 px-1">
                  <div
                    className="cursor-pointer h-40 el relative z-50 hasDeleteHover overflow-hidden"
                  >
                    <ClearIcon className={""} style={{ display: "none" }} />
                    <img
                      src={mediaData.media}
                      alt="img"
                      className="object-cover h-full w-full"
                      onClick={(e) => setImage(e, mediaData, id)}
                    />
                    <button
                      style={{ backgroundColor: "#fbb13c" }}
                      className="absolute font-san py-1 text-sm deleteModalImage w-full"
                      onClick={(e) => deleteImage(e, mediaData.mediaId)}
                    >
                      Delete
                    </button>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="py-2 border-t border-gray-300">
            <Col md={12}>
              <div className=" text-right">
                <Button
                  className="rounded py-2 px-3 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                >
                  Add Image
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modals>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setHotelAddonTitles: (value, id) => {
      dispatch(setHotelAddonTitle(value, id));
    },
    setHotelAddonDescriptions: (value, id) => {
      dispatch(setHotelAddonDescription(value, id));
    },
    setHotelAddonImages: (value, id) => {
      dispatch(setHotelAddonImage(value, id));
    },
    fetchImageLibrarys: (value) => {
      dispatch(fetchImageLibrary(value));
    },
    setModalAddonImages: (value,id) => {
      dispatch(setModalAddonImage(value,id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(HotelFeature);
