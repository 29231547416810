import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../forms/button/Button";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import { RxReload } from "react-icons/rx";
import {
  setHotelDetailImage,
  setHotelName,
  setHotelDescription,
  setHotelAddress,
  setHotelPincode,
  setHotelContact,
  setHotelCheckin,
  setHotelCheckout,
  addHotelAmenFeat
} from "../../services/actions";
import ListInput from "../forms/Listinput/ListInput";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function HotelDetails({
  roomDetailData,
  setHotelDetailImages,
  setHotelNames,
  setHotelDescriptions,
  setHotelAddresses,
  setHotelPincodes,
  setHotelContacts,
  setHotelCheckins,
  setHotelCheckouts,
  addHotelAmenFeats
}) {
  var token = cookies.get("nw_token");
  const [isLoading,setIsLoading] = useState(false)
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setHotelDetailImages(file);
  };
  const saveHotelDetail = (e) => {
    setIsLoading(true)
    e.preventDefault()
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id);
    fd.append("hotelName", roomDetailData.hotelName);
    fd.append("description", roomDetailData.hotelDescription);
    fd.append("address", roomDetailData.hotelAddress);
    fd.append("contact", roomDetailData.hotelContact);
    fd.append("pincode", roomDetailData.hotelPincode);
    fd.append("checkIn", roomDetailData.hotelCheckIn);
    fd.append("checkOut", roomDetailData.hotelCheckOut);
    fd.append("amenities", roomDetailData.hotelAmen);
    fd.append("bannerImage", roomDetailData.hotelRoomImageFile ? roomDetailData.hotelRoomImageFile : roomDetailData.hotelRoomImage);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/updateHotelDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        if (response.data.message === "Hotel Details Updated") {
          alert("Page Updated");
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };
  const list = (data) => {
    addHotelAmenFeats(data)
  };
  return (
    <>
      <form onSubmit={saveHotelDetail}>
        <Row>
          <Col md={4} className="mb-4">
            <div className="w-full" onClick={handleImageClick}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <img
                src={roomDetailData.hotelRoomImage}
                alt="hotel"
                className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
              />
            </div>
          </Col>
          <Col md={12} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Hotel Name
              </label>
              <input
                className="nr_form_control"
                type="text"
                value={roomDetailData.hotelName}
                readOnly="true"
                onChange={(e) => setHotelNames(e.target.value)}
              />
              <span className=" text-gray-500 text-sm bg-red-50 px-2 py-1 rounded relative top-2">Manager Can't Change the hotel name</span>
            </div>
          </Col>
          <Col md={12} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Description
                 <span className=" text-red-600 text-base">*</span>
              </label>
              <textarea
                className="nr_form_control"
                style={{ height: "290px" }}
                value={roomDetailData.hotelDescription}
                required
                onChange={(e) => setHotelDescriptions(e.target.value)}
              ></textarea>
            </div>
          </Col>
          <Col md={8} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Address
                <span className=" text-red-600 text-base">*</span>
              </label>
              <input
                className="nr_form_control"
                type="text"
                value={roomDetailData.hotelAddress}
                required
                onChange={(e) => setHotelAddresses(e.target.value)}
              />
            </div>
          </Col>
          <Col md={4} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Pincode
                <span className=" text-red-600 text-base">*</span>
              </label>
              <input
                className="nr_form_control"
                type="text"
                required
                value={roomDetailData.hotelPincode}
                onChange={(e) => setHotelPincodes(e.target.value)}
              />
            </div>
          </Col>
          <Col md={4} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Contact
                <span className=" text-red-600 text-base">*</span>
              </label>
              <input
                className="nr_form_control"
                type="tel"
                required
                value={roomDetailData.hotelContact}
                onChange={(e) => setHotelContacts(e.target.value)}
              />
            </div>
          </Col>
          <Col md={4} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Check-in Time
                <span className=" text-red-600 text-base">*</span>
              </label>
              <input
                className="nr_form_control"
                type="time"
                required
                value={roomDetailData.hotelCheckIn}
                onChange={(e) => setHotelCheckins(e.target.value)}
              />
            </div>
          </Col>
          <Col md={4} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Check-out Time
                <span className=" text-red-600 text-base">*</span>
              </label>
              <input
                className="nr_form_control"
                type="time"
                required
                value={roomDetailData.hotelCheckOut}
                onChange={(e) => setHotelCheckouts(e.target.value)}
              />
            </div>
          </Col>
          <Col md={12} className="">
            {roomDetailData.hotelAmen.length > 0 ? (
              <ListInput
                label="Amenities"
                listData={roomDetailData.hotelAmen}
                list={(data) => list(data)}
                className="w-2/5"
              />
            ) : (
              ""
            )}
          </Col>
          <Col md={2}>
            <Button
              className="w-full rounded py-2 px-2 text-white roboto"
              type="submit"
              disabled={isLoading}
              style={{ backgroundColor: "var(--primaryHotel)" }}
            >
              <div className="flex items-center justify-center">
                Save
                {isLoading && <RxReload className=" ml-2 w-4 h-4 animate-spin"/>}
              </div>
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToState = (dispatch) => {
  return {
    setHotelDetailImages: (file) => {
      dispatch(setHotelDetailImage(file));
    },
    setHotelNames: (value) => {
      dispatch(setHotelName(value));
    },
    setHotelDescriptions: (value) => {
      dispatch(setHotelDescription(value));
    },
    setHotelAddresses: (value) => {
      dispatch(setHotelAddress(value));
    },
    setHotelPincodes: (value) => {
      dispatch(setHotelPincode(value));
    },
    setHotelContacts: (value) => {
      dispatch(setHotelContact(value));
    },
    setHotelCheckins: (value) => {
      dispatch(setHotelCheckin(value));
    },
    setHotelCheckouts: (value) => {
      dispatch(setHotelCheckout(value));
    },
    addHotelAmenFeats: (value) => {
      dispatch(addHotelAmenFeat(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToState)(HotelDetails);
