//POLLS ACTION 
export const LANDING_PAGE_DATA = "LANDING_PAGE_DATA"
export const GET_ROOM_DETAIL_DATA = "GET_ROOM_DETAIL_DATA"
export const GET_HOTEL_PAGE_DATA = "GET_HOTEL_PAGE_DATA"
export const GET_HOTEL_DETAIL_DATA = "GET_HOTEL_DETAIL_DATA"
export const SET_BANNER_TITLE = "SET_BANNER_TITLE"
export const SET_BANNER_DESCRIPTION = "SET_BANNER_DESCRIPTION"
export const SET_EXTRA_DESCRIPTION = "SET_EXTRA_DESCRIPTION"
export const SET_AMEN_TITLE = "SET_AMEN_TITLE"
export const SET_AMEN_DESCRIPTION = "SET_AMEN_DESCRIPTION"
export const GET_HOTEL_FEATURE_TITLE = "GET_HOTEL_FEATURE_TITLE"
export const GET_HOTEL_FEATURE_DESCRIPTION = "GET_HOTEL_FEATURE_DESCRIPTION"
export const GET_HOTEL_ADDON_TITLE = "GET_HOTEL_ADDON_TITLE"
export const GET_HOTEL_ADDON_DESCRIPTION = "GET_HOTEL_ADDON_DESCRIPTION"
export const GET_HOTEL_DETAIL_PAGE_TITLE = "GET_HOTEL_DETAIL_PAGE_TITLE"
export const GET_HOTEL_DETAIL_PAGE_DESCRIPTION = "GET_HOTEL_DETAIL_PAGE_DESCRIPTION"
export const SET_BANNER_IMAGE = "SET_BANNER_IMAGE"
export const SET_EXTRA_BANNER_IMAGE = "SET_EXTRA_BANNER_IMAGE"
export const SET_HOTEL_DETAIL_IMAGE = "SET_HOTEL_DETAIL_IMAGE"
export const SET_LANDING_AMEN_IMAGE = "SET_LANDING_AMEN_IMAGE"
export const SET_HOTEL_NAME = "SET_HOTEL_NAME"
export const SET_HOTEL_DESCRIPTION = "SET_HOTEL_DESCRIPTION"
export const SET_HOTEL_ADDRESS = "SET_HOTEL_ADDRESS"
export const SET_HOTEL_PINCODE = "SET_HOTEL_PINCODE"
export const SET_HOTEL_CONTACT = "SET_HOTEL_CONTACT"
export const SET_HOTEL_CHECKIN = "SET_HOTEL_CHECKIN"
export const SET_HOTEL_CHECKOUT = "SET_HOTEL_CHECKOUT"
export const SET_HOTEL_PAGE_TITLE = "SET_HOTEL_PAGE_TITLE"
export const SET_HOTEL_PAGE_DESCRIPTION = "SET_HOTEL_PAGE_DESCRIPTION"
export const SET_HOTEL_AMEN_IMAGE = "SET_HOTEL_AMEN_IMAGE"
export const SET_HOTEL_ADDON_IMAGE = "SET_HOTEL_ADDON_IMAGE"
export const SET_ROOM_FEATURE_LIST = "SET_ROOM_FEATURE_LIST"
export const SET_IMAGE_LIBRARY = "SET_IMAGE_LIBRARY"
export const SET_ICON_LIBRARY = "SET_ICON_LIBRARY"
export const SET_MEDIA_ROOM_DETAILS = "SET_MEDIA_ROOM_DETAILS"
export const SET_MEDIA_HOTEL_BANNER = "SET_MEDIA_HOTEL_BANNER"
export const UNCHECK_MEDIA_HOTEL_BANNER = "UNCHECK_MEDIA_HOTEL_BANNER"
export const ADD_HOTEL_AMEN_FEAT = "ADD_HOTEL_AMEN_FEAT"
export const SET_MODAL_AMEN_IMAGE = "SET_MODAL_AMEN_IMAGE"
export const SET_MODAL_AMEN_ICON_IMAGE = "SET_MODAL_AMEN_ICON_IMAGE"
export const SET_MODAL_ADD_ON_IMAGE = "SET_MODAL_ADD_ON_IMAGE"
export const REMOVE_IMAGE_FROM_CAROUSEL = "REMOVE_IMAGE_FROM_CAROUSEL"
export const SET_HOTEL_TO_MANAGER = "SET_HOTEL_TO_MANAGER"
export const ROMOVE_HOTEL_ASSIGNED = "ROMOVE_HOTEL_ASSIGNED"
export const SET_HOTEL_ASSIGN = "SET_HOTEL_ASSIGN"

