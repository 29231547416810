import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DashboardCard from "../../../../components/forms/cards/DashboardCard";
import axios from "axios";
import config from "../../../../config";
import Cookies from "universal-cookie";
import { pushToAuth } from "../../../../generalFunction";
import { useHistory } from "react-router-dom";
import FadeLoader from "../../../../components/loader/FadeLoader";
const cookies = new Cookies();
function ManagerDashboard() {
  const [assignedHotelData, setHotelAssignedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  var history = useHistory();
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  const getHotel = () => {
    setIsLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("pageNo", 0);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getHotelList`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        setHotelAssignedData(response.data.mediaList);
      })
      .catch(function (err) {
        alert(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };
  useEffect(() => {
    {
      token && getHotel();
    }
  }, []);
  return (
    <>
      {user === "manager" ? (
        <React.Fragment>
          <Container className="py-4">
            {isLoading
              ? <FadeLoader />
              : <Row>
                {assignedHotelData?.map((hotelLists, idx) => {
                  return (
                    <Col md={3} key={idx}>
                      <DashboardCard hotelId="1" hotelData={hotelLists} />
                    </Col>
                  );
                })}
              </Row>}
          </Container>
        </React.Fragment>
      ) : (
        history.push("/")
      )}
    </>
  );
}

export default ManagerDashboard;
