import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { removeImageFromCarousel } from "../../services/actions"
function CarLightBox({ hotelPageData, removeImageFromCarousels,isRemoveClicked }) {
  return (
    <>
      {hotelPageData?.hotelBannerCar.length > 0 ? <Col
        md={12}
        className="flex absolute py-4 w-full"
        style={{ backgroundColor: "#000000ba" }}
      >
        {hotelPageData.hotelBannerCar.map((bannerData, idx) => {
          return (
            <div key={idx} className="mr-3 cursor-pointer relative">
              <img
                src={bannerData.media}
                alt=""
                className="w-24 h-24 object-cover"
              />
              <button
                className="absolute font-san py-1 text-sm deleteModalImage w-full"
                style={{ backgroundColor: "#fbb13c", transform: "translateY(-28px)" }}
                onClick={() => removeImageFromCarousels(bannerData)}
              >
                Remove
              </button>
            </div>
          );
        })}
      </Col> : ""}

    </>
  );
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToState = (dispatch) => {
  return {
    removeImageFromCarousels: (value) => {
      dispatch(removeImageFromCarousel(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(CarLightBox);
