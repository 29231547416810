import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../components/forms/button/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory, Link } from "react-router-dom";
import "./managerHotel.css";
import axios from "axios";
import config from "../../../config";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { throwError } from "../../../errorHandling";
const cookies = new Cookies();
function ManagerHotel() {
  var url = window.location.pathname;
  var urlHotelID = url.substring(url.lastIndexOf("/") + 1);
  var token = cookies.get("nw_token");
  const history = useHistory();
  const createUser = () => {
    history.push("/createhotel");
  };
  const [hotelList, setHotelList] = useState([]);
  const [selectedActive, setSelectedActive] = useState("");
  const getHotelList = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("pageNo", 0);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/getHotelList`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        setHotelList(response.data.hotelDetails);
      })
      .catch(function (err) {
        // console.log(err);
        throwError(err)
      });
  };
  useEffect(() => {
    getHotelList();
  }, []);
  const hotelStatus = (value, hotelId) => {
    setSelectedActive(value);
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", hotelId);
    fd.append("activeStatus", value);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateHotelActiveStatus`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        getHotelList();
        toast.success('Hotel Status Updated');
      })
      .catch(function (err) {
        throwError(err)
      });
  };
  return (
    <>
      <Container className="py-8" style={{ minHeight: "calc(100vh - 130px)" }}>
        <Row>
          {/* <Col md={12} style={{ textAlign: "-webkit-right" }}>
            <div className="w-48 mb-4">
              <Button
                className="w-full rounded py-3 px-2 text-white roboto"
                type="button"
                style={{ backgroundColor: "var(--primaryHotel)" }}
                onClick={() => createUser()}
              >
                Create Hotel
              </Button>
            </div>
          </Col> */}
          <Col md={12}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Sr.no</th>
                  <th className="w-64">Hotel Image</th>
                  <th>Hotel Name</th>
                  <th>Hotel Manager</th>
                  <th>Hotel Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="managerHotelBody">
                {hotelList.map((hotelList, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <div className="w-48 h-32">
                          <img
                            src={hotelList.bannerImage}
                            alt="b1"
                            className="img-responsive h-full w-full object-cover"
                          />
                        </div>
                      </td>
                      <td>{hotelList.name}</td>
                      <td>
                        {hotelList?.hotelManager?.map((hotelManager, managerIdx) => {
                          return <p key={managerIdx} className=" text-left">{hotelManager.fullName}</p>
                        })}
                      </td>
                      <td>
                        <div className="">
                          <select
                            className="w-full p-1 rounded"
                            onChange={(e) =>
                              hotelStatus(e.target.value, hotelList.hotelId)
                            }
                          >
                            <option value="">---select----</option>
                            <option
                              value="1"
                              selected={
                                hotelList.hotelActiveStatus === "1"
                                  ? true
                                  : false
                              }
                            >
                              Active
                            </option>
                            <option
                              value="0"
                              selected={
                                hotelList.hotelActiveStatus === "0"
                                  ? true
                                  : false
                              }
                            >
                              In-active
                            </option>
                          </select>
                        </div>
                      </td>
                      <td>
                        {hotelList.hotelActiveStatus === '1' 
                        ? <Link to={`/edithotel/${hotelList.hotelId}`}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Link> 
                        : <small className="text-red-500">Hotel is In-active</small>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ManagerHotel;
