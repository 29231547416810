import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ListInput from "../forms/Listinput/ListInput";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import Button from "../forms/button/Button";
import PublishIcon from "@material-ui/icons/Publish";
import ClearIcon from '@material-ui/icons/Clear';
import {
  getHotelPageDetailTitle,
  getHotelPageDetailDescription,
  roomFeatureListAdd,
  fetchImageLibrary,
  setMediaForRoomDetails
} from "../../services/actions";
import Modals from "../modal/Modals";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function RoomInfo({
  roomDetailDatas,
  globalReducer,
  id,
  getHotelPageDetailTitles,
  getHotelPageDetailDescriptions,
  roomFeatureListAdds,
  fetchImageLibrarys,
  setMediaForRoomDetail
}) {
  var token = cookies.get("nw_token");
  var url = window.location.pathname;
  var url_id = url.substring(url.lastIndexOf("/") + 1);
  const list = (data, index, compType) => {
    roomFeatureListAdds(data, index, compType);
  };
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    addImage(file);
  };
  const addImage = (file) => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", url_id);
    fd.append("action", "ADD");
    fd.append("hotelImage", file);
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/manageHotelMedia`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        getHotelMedia();
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const getHotelMedia = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", url_id);
    fd.append("page_no", "0");
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/getMediaLibrary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        fetchImageLibrarys(response.data.mediaList);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openImageGallery = () => {
    setIsModalOpen(true);
  };
  const setImage = (e, mediaData,componentid) => {
    var el = document.querySelectorAll(".el");
    var parentElem = e.target.parentNode;
    el.forEach((elm) => {
      elm.classList.remove("activeImageCheck");
    })
    parentElem.classList.add("activeImageCheck");
    setMediaForRoomDetail(mediaData,componentid)
  };
  return (
    <>
      <Row className="border border-gray-300 px-3 py-4 rounded mb-4">
        <Col md={6}>
          <div>
            <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
              Image
            </label>
            <img
              src={roomDetailDatas.roomImage}
              onClick={() => openImageGallery()}
              alt="hotel"
              className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
            />
          </div>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={9}>
              <div className="w-full mb-4">
                <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                  Title
                </label>
                <input
                  className="nr_form_control"
                  type="text"
                  readOnly="true"
                  value={roomDetailDatas.title}
                  onChange={(e) => getHotelPageDetailTitles(e.target.value, id)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="w-full mb-4">
                <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                  Description
                </label>
                <textarea
                  className="nr_form_control"
                  style={{ height: "190px" }}
                  value={roomDetailDatas.description}
                  onChange={(e) =>
                    getHotelPageDetailDescriptions(e.target.value, id)
                  }
                ></textarea>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <ListInput
            label="In Room"
            listData={roomDetailDatas.inRoom}
            list={(data) => list(data, id, "inRoom")}
          />
        </Col>
        <Col md={4}>
          <ListInput
            label="Bathroom"
            listData={roomDetailDatas.bathroom}
            list={(data) => list(data, id, "bathroom")}
          />
        </Col>
        <Col md={4}>
          <ListInput
            label="Sleep"
            listData={roomDetailDatas.sleep}
            list={(data) => list(data, id, "sleep")}
          />
        </Col>
      </Row>
      <Modals
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="py-2 border-b border-gray-300">
            <Col md={12}>
              <div className="">
                <h3 className="mb-0">Add Image</h3>
              </div>
            </Col>
          </Row>
          <Row className="py-2 px-2 max-h-96 overflow-y-scroll">
            <Col md={3}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <div
                onClick={handleImageClick}
                className="h-40 flex flex-col cursor-pointer rounded hover:shadow border-2 items-center justify-center text-center text-gray-400 border-dashed border-gray-300"
              >
                <PublishIcon />
                click To Upload
              </div>
            </Col>
            {globalReducer.mediaLibrary.map((mediaData, idx) => {
              return (
                <Col md={3} key={idx} className="mb-2 px-1">
                  <div
                    className={`cursor-pointer h-40 relative z-50 el`}
                    data-id={mediaData.mediaId}
                    onClick={(e) => setImage(e, mediaData,id)}
                  >
                    <ClearIcon className={""} style={{ display: "none" }} />
                    <img
                      src={mediaData.media}
                      alt="img"
                      className="object-cover h-full w-full"
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row className="py-2 border-t border-gray-300">
            <Col md={12}>
              <div className=" text-right">
                <Button
                  className="rounded py-2 px-3 text-white roboto"
                  type="button"
                  style={{ backgroundColor: "var(--primaryHotel)" }}
                >
                  Add Image
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modals>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    getHotelPageDetailTitles: (value, id) => {
      dispatch(getHotelPageDetailTitle(value, id));
    },
    getHotelPageDetailDescriptions: (value, id) => {
      dispatch(getHotelPageDetailDescription(value, id));
    },
    roomFeatureListAdds: (value, id, compType) => {
      dispatch(roomFeatureListAdd(value, id, compType));
    },
    fetchImageLibrarys: (value) => {
      dispatch(fetchImageLibrary(value));
    },
    setMediaForRoomDetail: (value,componentid) => {
      dispatch(setMediaForRoomDetails(value,componentid));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(RoomInfo);
