import {
  GET_HOTEL_PAGE_DATA,
  GET_HOTEL_FEATURE_TITLE,
  GET_HOTEL_FEATURE_DESCRIPTION,
  GET_HOTEL_ADDON_TITLE,
  GET_HOTEL_ADDON_DESCRIPTION,
  SET_HOTEL_PAGE_TITLE,
  SET_HOTEL_PAGE_DESCRIPTION,
  SET_HOTEL_AMEN_IMAGE,
  SET_HOTEL_ADDON_IMAGE,
  SET_MEDIA_HOTEL_BANNER,
  UNCHECK_MEDIA_HOTEL_BANNER,
  SET_MODAL_AMEN_IMAGE,
  SET_MODAL_AMEN_ICON_IMAGE,
  SET_MODAL_ADD_ON_IMAGE,
  REMOVE_IMAGE_FROM_CAROUSEL,
} from "../constant";
const initialFormsData = {
  hotelPage: "",
  hotelBannerCar: [],
  hotelBannerId: [],
  titleContainer_Title: "",
  titleContainer_Content: "",
  amenitiesContainer: [],
  amenitiesContainerImage: [
    { amenConImage: "" },
    { amenConImage: "" },
    { amenConImage: "" },
  ],
  addOnContaier: [],
  addonsContainerImage: [
    { addonsConImage: "" },
    { addonsConImage: "" },
    { addonsConImage: "" },
    { addonsConImage: "" },
    { addonsConImage: "" },
    { addonsConImage: "" },
  ],
  amenitiesContaierSendData: [
    {
      componentId: 3,
      mediaId: "",
      iconId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 4,
      mediaId: "",
      iconId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 5,
      mediaId: "",
      iconId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
  ],
  addOnContaierSendData: [
    {
      componentId: 6,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 7,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 8,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 14,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 15,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
    {
      componentId: 16,
      mediaId: "",
      title: "",
      content: "",
      activeStatus: 1,
    },
  ],
  titleContainer: {
    title: "",
    content: "",
  },
};

const hotelPageData = (state = initialFormsData, action) => {
  switch (action.type) {
    case GET_HOTEL_PAGE_DATA: {
      action.payload.amenitiesContaier.map((amenData, idx) => {
        state.amenitiesContaierSendData[idx].title = amenData.title;
        state.amenitiesContaierSendData[idx].content = amenData.content;
        state.amenitiesContaierSendData[idx].mediaId = amenData.media_id;
        state.amenitiesContaierSendData[idx].iconId = amenData.icon_id;
        state.amenitiesContaierSendData[idx].componentId = amenData.componentId;
      });
      action.payload.addOnContaier.map((addData, idx) => {
        state.addOnContaierSendData[idx].title = addData.title;
        state.addOnContaierSendData[idx].content = addData.content;
        state.addOnContaierSendData[idx].mediaId = addData.media_id;
        state.addOnContaierSendData[idx].componentId = addData.componentId;
      });
      action.payload.bannerCarousel.map((media) => {
        state.hotelBannerId.push(media.mediaId);
      });
      state.titleContainer = {
        title: action.payload.titleContaier[0]?.title,
        content: action.payload.titleContaier[0]?.content,
      };
      return {
        ...state,
        hotelBannerCar: action.payload.bannerCarousel,
        titleContainer_Title: action.payload.titleContaier[0]?.title,
        titleContainer_Content: action.payload.titleContaier[0]?.content,
        amenitiesContainer: action.payload.amenitiesContaier,
        addOnContaier: action.payload.addOnContaier,
      };
    }
    case GET_HOTEL_FEATURE_TITLE: {
      state.amenitiesContainer[action.payload.id].title = action.payload.value;
      state.amenitiesContaierSendData[action.payload.id].title =
        action.payload.value;
      return {
        ...state,
      };
    }
    case GET_HOTEL_FEATURE_DESCRIPTION: {
      state.amenitiesContainer[action.payload.id].content =
        action.payload.value;
      state.amenitiesContaierSendData[action.payload.id].content =
        action.payload.value;
      return {
        ...state,
      };
    }
    case GET_HOTEL_ADDON_TITLE: {
      state.addOnContaier[action.payload.id].title = action.payload.value;
      state.addOnContaierSendData[action.payload.id].title =
        action.payload.value;
      return {
        ...state,
      };
    }
    case GET_HOTEL_ADDON_DESCRIPTION: {
      state.addOnContaier[action.payload.id].content = action.payload.value;
      state.addOnContaierSendData[action.payload.id].content =
        action.payload.value;
      return {
        ...state,
      };
    }
    case SET_HOTEL_PAGE_TITLE: {
      state.titleContainer.title = action.payload;
      return {
        ...state,
        titleContainer_Title: action.payload,
      };
    }
    case SET_HOTEL_PAGE_DESCRIPTION: {
      state.titleContainer.content = action.payload;
      return {
        ...state,
        titleContainer_Content: action.payload,
      };
    }
    case SET_HOTEL_AMEN_IMAGE: {
      state.amenitiesContainer[action.payload.id].media = URL.createObjectURL(
        action.payload.file
      );
      state.amenitiesContainerImage[action.payload.id].amenConImage =
        action.payload.file;
      return {
        ...state,
      };
    }
    case SET_HOTEL_ADDON_IMAGE: {
      console.log(action.payload);
      state.addOnContaier[action.payload.id].media = URL.createObjectURL(
        action.payload.file
      );
      state.addonsContainerImage[action.payload.id].addonsConImage =
        action.payload.file;
      return {
        ...state,
      };
    }
    case SET_MEDIA_HOTEL_BANNER: {
      var da = state.hotelBannerCar;
      da.push(action.payload);
      state.hotelBannerId.push(action.payload.mediaId);
      return {
        ...state,
      };
    }
    case UNCHECK_MEDIA_HOTEL_BANNER: {
      var indexOfMain = state.hotelBannerCar.findIndex(
        (item) => item.mediaId === action.payload.mediaId
      );
      state.hotelBannerCar.splice(indexOfMain, 1);
      var indexOfId = state.hotelBannerId.indexOf(action.payload.mediaId);
      state.hotelBannerId.splice(indexOfId, 1);
      return {
        ...state,
      };
    }
    case SET_MODAL_AMEN_IMAGE: {
      // console.log("action",action)
      state.amenitiesContainer[action.payload.id].media =
        action.payload.value.media;
      state.amenitiesContaierSendData[action.payload.id].mediaId =
        action.payload.value.mediaId;
      return {
        ...state,
      };
    }
    case SET_MODAL_AMEN_ICON_IMAGE: {
      state.amenitiesContainer[action.payload.id].icon =
        action.payload.value.media;
      state.amenitiesContaierSendData[action.payload.id].iconId =
        action.payload.value.id;
      return {
        ...state,
      };
    }
    case SET_MODAL_ADD_ON_IMAGE: {
      state.addOnContaier[action.payload.id].media = action.payload.value.media;
      state.addOnContaierSendData[action.payload.id].mediaId =
        action.payload.value.mediaId;
      return {
        ...state,
      };
    }
    case REMOVE_IMAGE_FROM_CAROUSEL: {
      if (state.hotelBannerId.length <= 1) {
        alert("One Banner Image Should be Present");
      } else {
        var indexOfMain = state.hotelBannerCar.findIndex(
          (item) => item.mediaId === action.payload.mediaId
        );
        state.hotelBannerCar.splice(indexOfMain, 1);
        var indexOfId = state.hotelBannerId.indexOf(action.payload.mediaId);
        state.hotelBannerId.splice(indexOfId, 1);
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default hotelPageData;
