import {
  LANDING_PAGE_DATA,
  GET_ROOM_DETAIL_DATA,
  GET_HOTEL_PAGE_DATA,
  GET_HOTEL_DETAIL_DATA,
  SET_BANNER_TITLE,
  SET_BANNER_DESCRIPTION,
  SET_EXTRA_DESCRIPTION,
  SET_AMEN_TITLE,
  SET_AMEN_DESCRIPTION,
  GET_HOTEL_FEATURE_TITLE,
  GET_HOTEL_FEATURE_DESCRIPTION,
  GET_HOTEL_ADDON_TITLE,
  GET_HOTEL_ADDON_DESCRIPTION,
  GET_HOTEL_DETAIL_PAGE_TITLE,
  GET_HOTEL_DETAIL_PAGE_DESCRIPTION,
  SET_BANNER_IMAGE,
  SET_EXTRA_BANNER_IMAGE,
  SET_HOTEL_DETAIL_IMAGE,
  SET_LANDING_AMEN_IMAGE,
  SET_HOTEL_NAME,
  SET_HOTEL_DESCRIPTION,
  SET_HOTEL_ADDRESS,
  SET_HOTEL_PINCODE,
  SET_HOTEL_CONTACT,
  SET_HOTEL_CHECKIN,
  SET_HOTEL_CHECKOUT,
  SET_HOTEL_PAGE_TITLE,
  SET_HOTEL_PAGE_DESCRIPTION,
  SET_HOTEL_AMEN_IMAGE,
  SET_HOTEL_ADDON_IMAGE,
  SET_ROOM_FEATURE_LIST,
  SET_IMAGE_LIBRARY,
  SET_ICON_LIBRARY,
  SET_MEDIA_ROOM_DETAILS,
  SET_MEDIA_HOTEL_BANNER,
  UNCHECK_MEDIA_HOTEL_BANNER,
  ADD_HOTEL_AMEN_FEAT,
  SET_MODAL_AMEN_IMAGE,
  SET_MODAL_AMEN_ICON_IMAGE,
  SET_MODAL_ADD_ON_IMAGE,
  REMOVE_IMAGE_FROM_CAROUSEL,
  SET_HOTEL_TO_MANAGER,
  ROMOVE_HOTEL_ASSIGNED,
  SET_HOTEL_ASSIGN
} from "./constant";

export const getLanding = (value) => {
  return {
    type: LANDING_PAGE_DATA,
    payload: value,
  };
};
export const setBannerImage = (file) => {
  return {
    type: SET_BANNER_IMAGE,
    payload: file,
  };
};
export const setBannerTitle = (value) => {
  return {
    type: SET_BANNER_TITLE,
    payload: value,
  };
};
export const setBannerDescription = (value) => {
  return {
    type: SET_BANNER_DESCRIPTION,
    payload: value,
  };
};
export const setExtraDescription = (value) => {
  return {
    type: SET_EXTRA_DESCRIPTION,
    payload: value,
  };
};
export const setAmenTitle = (value,idx) => {
  return {
    type: SET_AMEN_TITLE,
    payload: {value,idx},
  };
};
export const setAmenDescription = (value,idx) => {
  return {
    type: SET_AMEN_DESCRIPTION,
    payload: {value,idx},
  };
};
export const setExtraImage = (file) => {
  return {
    type: SET_EXTRA_BANNER_IMAGE,
    payload: file
  };
};



export const getRoomDetail = (value) => {
  return {
    type: GET_ROOM_DETAIL_DATA,
    payload: value,
  };
};
// HOTEL PAGE
export const getHotelPageDetail = (value) => {
  return {
    type: GET_HOTEL_PAGE_DATA,
    payload: value,
  };
};
export const setHotelFeatureTitle = (value,id) => {
  return {
    type: GET_HOTEL_FEATURE_TITLE,
    payload: {value,id},
  };
};
export const setHotelFeatureDescription = (value,id) => {
  return {
    type: GET_HOTEL_FEATURE_DESCRIPTION,
    payload: {value,id},
  };
};

export const setHotelAddonTitle = (value,id) => {
  return {
    type: GET_HOTEL_ADDON_TITLE,
    payload: {value,id},
  };
};
export const setHotelAddonDescription = (value,id) => {
  return {
    type: GET_HOTEL_ADDON_DESCRIPTION,
    payload: {value,id},
  };
};

export const getHotelDetail = (value) => {
  return {
    type: GET_HOTEL_DETAIL_DATA,
    payload: value,
  };
};
export const getHotelPageDetailTitle = (value,idx) => {
  return {
    type: GET_HOTEL_DETAIL_PAGE_TITLE,
    payload: {value,idx},
  };
};
export const getHotelPageDetailDescription = (value,idx) => {
  return {
    type: GET_HOTEL_DETAIL_PAGE_DESCRIPTION,
    payload: {value,idx},
  };
};



// HOTEL DETAIL TAB
export const setHotelDetailImage = (file) => {
  return {
    type: SET_HOTEL_DETAIL_IMAGE,
    payload: file,
  };
};
export const setLandingAmenImage = (file,id) => {
  return {
    type: SET_LANDING_AMEN_IMAGE,
    payload: {file,id},
  };
};


// HOTEL DETAILS
export const setHotelName = (value) => {
  return {
    type: SET_HOTEL_NAME,
    payload: value,
  };
};
export const setHotelDescription = (value) => {
  return {
    type: SET_HOTEL_DESCRIPTION,
    payload: value,
  };
};
export const setHotelAddress = (value) => {
  return {
    type: SET_HOTEL_ADDRESS,
    payload: value,
  };
};
export const setHotelPincode = (value) => {
  return {
    type: SET_HOTEL_PINCODE,
    payload: value,
  };
};
export const setHotelContact = (value) => {
  return {
    type: SET_HOTEL_CONTACT,
    payload: value,
  };
};
export const setHotelCheckin = (value) => {
  return {
    type: SET_HOTEL_CHECKIN,
    payload: value,
  };
};
export const setHotelCheckout = (value) => {
  return {
    type: SET_HOTEL_CHECKOUT,
    payload: value,
  };
};


// HOTEL PAGE
export const setHotelPageTitle = (value) => {
  return {
    type: SET_HOTEL_PAGE_TITLE,
    payload: value,
  };
};
export const setHotelPageDescription = (value) => {
  return {
    type: SET_HOTEL_PAGE_DESCRIPTION,
    payload: value,
  };
};
export const setHotelAmenImage = (file,id) => {
  return {
    type: SET_HOTEL_AMEN_IMAGE,
    payload: {file,id},
  };
};
export const setHotelAddonImage = (file,id) => {
  return {
    type: SET_HOTEL_ADDON_IMAGE,
    payload: {file,id},
  };
};


// ROOM DETAILS
export const roomFeatureListAdd = (value,id,compType) =>{
  return {
    type: SET_ROOM_FEATURE_LIST,
    payload: {value,id,compType},
  };
}
export const addHotelAmenFeat = (value) => {
  return {
    type: ADD_HOTEL_AMEN_FEAT,
    payload: value
  };
}


// GET IMAGE LIBRARY
export const fetchImageLibrary = (value) =>{
  return {
    type: SET_IMAGE_LIBRARY,
    payload: value,
  };
}
export const fetchIconLibrary = (value) =>{
  return {
    type: SET_ICON_LIBRARY,
    payload: value,
  };
}
export const setMediaForRoomDetails = (value,componentid) =>{
  return {
    type: SET_MEDIA_ROOM_DETAILS,
    payload: {value,componentid},
  };
}
export const setMediaForHotelBanner = (value) =>{
  return {
    type: SET_MEDIA_HOTEL_BANNER,
    payload: value,
  };
}
export const unCheckMediaForHotelBanner = (value) =>{
  return {
    type: UNCHECK_MEDIA_HOTEL_BANNER,
    payload: value,
  };
}
export const setModalAmenImage = (value,id) =>{
  return {
    type: SET_MODAL_AMEN_IMAGE,
    payload: {value,id},
  };
}
export const setModalIconImage = (value,id) =>{
  return {
    type: SET_MODAL_AMEN_ICON_IMAGE,
    payload: {value,id},
  };
}
export const setModalAddonImage = (value,id) =>{
  return {
    type: SET_MODAL_ADD_ON_IMAGE,
    payload: {value,id},
  };
}
export const removeImageFromCarousel = (value) =>{
  return {
    type: REMOVE_IMAGE_FROM_CAROUSEL,
    payload: value
  };
}

// SET_HOTEL_TO_MANAGER
export const setHotelToManager = (value) =>{
  return {
    type: SET_HOTEL_TO_MANAGER,
    payload: value
  };
}
export const removeHotelAssigned = (value) =>{
  return {
    type: ROMOVE_HOTEL_ASSIGNED,
    payload: value
  };
}
export const setHotelAssign = (value) =>{
  return {
    type: SET_HOTEL_ASSIGN,
    payload: value
  };
}