import {
  LANDING_PAGE_DATA,
  SET_BANNER_TITLE,
  SET_BANNER_DESCRIPTION,
  SET_EXTRA_DESCRIPTION,
  SET_AMEN_TITLE,
  SET_AMEN_DESCRIPTION,
  SET_BANNER_IMAGE,
  SET_EXTRA_BANNER_IMAGE,
  SET_LANDING_AMEN_IMAGE
} from "../constant";
const initialFormsData = {
  landingpage: "",
  bannerTitle: "",
  bannerDescription: "",
  bannerImage: "",
  hotelExtraSection: [],
  hotelExtraSectionImage: [
    {hotelExtraSec: ""},
    {hotelExtraSec: ""},
    {hotelExtraSec: ""},
  ],
  hotelExtraImg: "",
  hotelExtraDescription: "",
  hotelAmenTitle1: "",
  hotelAmenImage1: "",
  hotelAmenDescription1: "",
  blogBannerImage: "",
  bannerImageFile: "",
  hotelExtraImgFile: "",
};

const landingPageData = (state = initialFormsData, action) => {
  switch (action.type) {
    case LANDING_PAGE_DATA: {
      return {
        ...state,
        bannerTitle: action.payload[0].title,
        bannerDescription: action.payload[0].description,
        bannerImage: action.payload[0].media,
        hotelExtraSection: [
          action.payload[1],
          action.payload[2],
          action.payload[3],
        ],
        hotelExtraImg: action.payload[4].media,
        hotelExtraDescription: action.payload[4].description,
      };
    }
    case SET_BANNER_TITLE: {
      return {
        ...state,
        bannerTitle: action.payload,
      };
    }
    case SET_BANNER_DESCRIPTION: {
      return {
        ...state,
        bannerDescription: action.payload,
      };
    }
    case SET_EXTRA_DESCRIPTION: {
      return {
        ...state,
        hotelExtraDescription: action.payload,
      };
    }
    case SET_AMEN_TITLE: {
      state.hotelExtraSection[action.payload.idx].title = action.payload.value;
      return {
        ...state,
      };
    }
    case SET_AMEN_DESCRIPTION: {
      state.hotelExtraSection[action.payload.idx].description =
        action.payload.value;
      return {
        ...state,
      };
    }
    case SET_BANNER_IMAGE: {
      state.bannerImageFile = action.payload
      state.bannerImage = URL.createObjectURL(action.payload)
      return {
        ...state,
      };
    }
    case SET_EXTRA_BANNER_IMAGE: {
      state.hotelExtraImgFile = action.payload
      state.hotelExtraImg = URL.createObjectURL(action.payload)
      return {
        ...state,
      };
    }
    case SET_LANDING_AMEN_IMAGE: {
      state.hotelExtraSection[action.payload.id].media = URL.createObjectURL(action.payload.file);
      state.hotelExtraSectionImage[action.payload.id].hotelExtraSec = action.payload.file;
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default landingPageData;
