import { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "../../../components/forms/button/Button";
import { connect } from "react-redux";
import axios from "axios"
import config from "../../../config";
import {
  setBannerTitle,
  setBannerDescription,
  setBannerImage,
} from "../../../services/actions";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function Banner({ landingPageData, setBannerTitles, setBannerDescriptions,setBannerImages }) {
  console.log({landingPageData});
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  var token = cookies.get("nw_token");
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setBannerImages(file)
  };

  const saveLandingBanner = () =>{
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("id", id);
    fd.append("component", "banner_image");
    fd.append("title", landingPageData.bannerTitle);
    fd.append("description", landingPageData.bannerDescription);
    fd.append("media", landingPageData.bannerImageFile);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateLandingPageComponent`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    }).then(function(response){
      console.log(response)
      if(response.data.message === "Component Updated"){
        alert("Page Updated")
      }
    }).catch(function(err){
      console.log(err)
      alert(err.response.data.message)
    })
  }
  return (
    <>
      <>
        <Row>
          <Col md={12}>
            <div className="banner-image h-96 w-full cursor-pointer" onClick={handleImageClick}>
              <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e)}
              />
              <img
                src={landingPageData.bannerImage}
                alt="bg"
                className="h-full w-full object-cover"
              />
            </div>
          </Col>
          <Col md={12} className="mt-4">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Title
              </label>
              <input
                className="nr_form_control"
                value={landingPageData.bannerTitle}
                onChange={(e) => setBannerTitles(e.target.value)}
                type="text"
              />
            </div>
          </Col>
          <Col md={12} className="">
            <div className="w-full mb-4">
              <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                Description
              </label>
              <textarea
                className="nr_form_control"
                style={{ height: "150px" }}
                value={landingPageData.bannerDescription}
                onChange={(e) => setBannerDescriptions(e.target.value)}
              ></textarea>
            </div>
          </Col>
          <Col md={12}>
            <Button
              className="rounded py-2 px-10 text-white roboto"
              type="button"
              style={{ backgroundColor: "var(--primaryHotel)" }}
              onClick={() => saveLandingBanner()}
            >
              Save
            </Button>
          </Col>
        </Row>
      </>
    </>
  );
}
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToState = (dispatch) => {
  return {
    setBannerTitles: (value) => {
      dispatch(setBannerTitle(value));
    },
    setBannerDescriptions: (value) => {
      dispatch(setBannerDescription(value));
    },
    setBannerImages: (file) => {
      dispatch(setBannerImage(file));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToState)(Banner);
