import React, { useState } from "react";
import RoomInfo from "./RoomInfo";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import Button from "../forms/button/Button";
import axios from "axios";
import config from "../../config";
import Cookies from "universal-cookie";
import { RxReload } from "react-icons/rx";
const cookies = new Cookies();
function RoomDetails({ hotelDetailData }) {
  var token = cookies.get("nw_token");
  var url = window.location.pathname;
  const [isLoading, setIsLoading] = useState(false)
  var id = url.substring(url.lastIndexOf("/") + 1);
  const saveRoomDetail = () => {
    setIsLoading(true)
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelId", id)
    fd.append("roomData", JSON.stringify(hotelDetailData.roomDetailsSendData));
    axios({
      method: "POST",
      url: `${config.base_url}Hotelmanager/updateRoomDetails`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    }).then(function (response) {
      if (response.data.message === "Hotel Details Updated") {
        alert("Page Updated")
      }
    }).catch(function (err) {
      console.log(err)
    })
      .finally(() => {
        setIsLoading(false)
      })
  };
  return (
    <>
      {hotelDetailData.roomdetails.map((roomDetailData, idx) => {
        return <RoomInfo roomDetailDatas={roomDetailData} key={idx} id={idx} />;
      })}
      <Col md={2}>
        <Button
          className="w-full rounded py-2 px-2 text-white roboto"
          type="button"
          disabled={isLoading}
          style={{ backgroundColor: "var(--primaryHotel)" }}
          onClick={() => saveRoomDetail()}
        >
          <div className="flex items-center justify-center">
            Save
            {isLoading && <RxReload className=" ml-2 w-4 h-4 animate-spin" />}
          </div>
        </Button>
      </Col>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

// const mapDispatchToState = (dispatch) => {
//   return {
//     getRoomDetailData: (value) => {
//       dispatch(getRoomDetail(value));
//     },
//     setHotelPageDetail: (value) => {
//       dispatch(getHotelPageDetail(value));
//     },
//     setHotelDetail: (value) => {
//       dispatch(getHotelDetail(value));
//     },
//   };
// };
export default connect(mapStateToProps, null)(RoomDetails);
