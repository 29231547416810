import React from "react";

function Footer() {
  return (
    <>
      <footer className="h-16 bg-gray-300">
        <div className="max-1400 h-full">
          <div className="flex w-full h-full items-center justify-center">
            <span>Copyright © 2024 Norwoods All rights reserved</span>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
