import { useRef } from "react";
import { Col } from "react-bootstrap";
import Button from "../../../components/forms/button/Button";
import { connect } from "react-redux";
import {setAmenTitle,setAmenDescription,setLandingAmenImage} from "../../../services/actions"
import axios from "axios"
import config from "../../../config";
import Cookies from "universal-cookie";
const cookies = new Cookies();
function HotelAmenties({ landingPageData, amenData,setAmenTitles,id,setAmenDescriptions,setLandingAmenImages}) {
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  var token = cookies.get("nw_token");
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e,index) => {
    var file = e.target.files[0];
    setLandingAmenImages(file,index)
  };
  const saveHotelAmen = (compID,index) =>{
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("id", compID);
    fd.append("component", "display_container");
    fd.append("title", landingPageData.hotelExtraSection[index].title);
    fd.append("description", landingPageData.hotelExtraSection[index].description);
    fd.append("media", landingPageData.hotelExtraSectionImage[index].hotelExtraSec);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/updateLandingPageComponent`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    }).then(function(response){
      if(response.data.message === "Component Updated"){
        alert("Page Updated")
      }
    }).catch(function(err){
      console.log(err)
      alert(err.response.data.message)
    })
  }
  return (
    <>
      <Col md={4}>
        <Col md={12} className="px-0">
          <div className="w-full mb-4">
            <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
              Title
            </label>
            <input className="nr_form_control" type="text" value={amenData.title} onChange={(e) => setAmenTitles(e.target.value,id)}/>
          </div>
        </Col>
        <Col md={12} className="mb-4 px-0">
          <div className="w-full" onClick={handleImageClick}>
          <input
                type="file"
                hidden
                accept="image/*"
                ref={fileUpload}
                onChange={(e) => uploadImage(e,id)}
              />
            <img
              src={amenData.media}
              alt="hotel"
              className="w-full h-52 object-cover p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
            />
          </div>
        </Col>
        <Col md={12} className="px-0">
          <div className="w-full mb-4">
            <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
              Description
            </label>
            <textarea
              className="nr_form_control"
              style={{ height: "170px" }}
              value={amenData.description}
              onChange={(e) => setAmenDescriptions(e.target.value,id)}
            ></textarea>
          </div>
        </Col>
        <Col md={12} className="px-0">
          <Button
            className="rounded py-2 px-10 text-white roboto"
            type="button"
            style={{ backgroundColor: "var(--primaryHotel)" }}
            onClick={() => saveHotelAmen(amenData.id,id)}
          >
            Save
          </Button>
        </Col>
      </Col>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToState = (dispatch) => {
  return {
    setAmenTitles: (value,id) => {
      dispatch(setAmenTitle(value,id));
    },
    setAmenDescriptions: (value,id) => {
      dispatch(setAmenDescription(value,id));
    },
    setLandingAmenImages: (value,id) => {
      dispatch(setLandingAmenImage(value,id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(HotelAmenties);
