import {
  GET_HOTEL_DETAIL_DATA,
  GET_HOTEL_DETAIL_PAGE_TITLE,
  GET_HOTEL_DETAIL_PAGE_DESCRIPTION,
  SET_ROOM_FEATURE_LIST,
  SET_MEDIA_ROOM_DETAILS
} from "../constant";
const initialFormsData = {
  roomdetails: [],
  roomDetailsSendData: [],
};
const hotelDetailData = (state = initialFormsData, action) => {
  // console.log(action);
  switch (action.type) {
    case GET_HOTEL_DETAIL_DATA: {
      action.payload.map((hotelDetailData, idx) => {
        state.roomDetailsSendData.push({
          roomId: hotelDetailData.refId,
          roomTitle: hotelDetailData.title,
          roomDescription: hotelDetailData.description,
          roomThumbId: hotelDetailData.roomImageId,
          // roomFeatures: {
          //   inRoom: JSON.stringify(hotelDetailData?.inRoom),
          //   bathroom: JSON.stringify(hotelDetailData?.bathroom),
          //   sleep: JSON.stringify(hotelDetailData?.sleep),
          // },
          roomFeatures: {
            inRoom: hotelDetailData?.inRoom,
            bathroom: hotelDetailData?.bathroom,
            sleep: hotelDetailData?.sleep,
          },
          roomThumb:hotelDetailData.roomImage,
        })
      });
      return {
        ...state,
        roomdetails: action.payload,
      };
    }
    case GET_HOTEL_DETAIL_PAGE_TITLE: {
      state.roomdetails[action.payload.idx].title = action.payload.value;
      state.roomDetailsSendData[action.payload.idx].roomTitle =
        action.payload.value;
      return {
        ...state,
      };
    }
    case GET_HOTEL_DETAIL_PAGE_DESCRIPTION: {
      state.roomdetails[action.payload.idx].description = action.payload.value;
      state.roomDetailsSendData[action.payload.idx].roomDescription =
        action.payload.value;
      return {
        ...state,
      };
    }
    case SET_ROOM_FEATURE_LIST: {
      if (action.payload.compType === "inRoom") {
        state.roomdetails[action.payload.id].inRoom = action.payload.value;
        state.roomDetailsSendData[action.payload.id].roomFeatures.inRoom = action.payload.value;
      }
      if (action.payload.compType === "bathroom") {
        state.roomdetails[action.payload.id].bathroom = action.payload.value;
        state.roomDetailsSendData[action.payload.id].roomFeatures.bathroom = action.payload.value;
      }
      if (action.payload.compType === "sleep") {
        state.roomdetails[action.payload.id].sleep = action.payload.value;
        state.roomDetailsSendData[action.payload.id].roomFeatures.sleep = action.payload.value;
      }
      return {
        ...state,
      };
    }
    case SET_MEDIA_ROOM_DETAILS: {
      state.roomdetails[action.payload.componentid].roomImageId = action.payload.value.mediaId;
      state.roomdetails[action.payload.componentid].roomImage = action.payload.value.media;
      state.roomDetailsSendData[action.payload.componentid].roomThumbId = action.payload.value.mediaId
      state.roomDetailsSendData[action.payload.componentid].roomThumb = action.payload.value.media
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default hotelDetailData;
