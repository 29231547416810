import axios from "axios";
import config from "./config";
import Cookies from "universal-cookie";

const cookies = new Cookies();
var token = cookies.get("nw_token");
export const searchHotelList = async (query) => {
  var fd = new FormData();
  fd.append("token", JSON.stringify(token));
  fd.append("identity", "norwoodinns");
  fd.append("pageNo", 0);
  fd.append("searchKey", query);
  const res = await axios({
    method: "post",
    data: fd,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${config.base_url}Hoteladmin/getHotelList`,
  });
  return await res.data;
};
