import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../components/forms/button/Button";
import ListinputSearch from "../../../components/forms/Listinput/ListinputSearch";
import axios from "axios";
import config from "../../../config";
import eye from "../../../assets/images/eye.png";
import noteye from "../../../assets/images/noteye.png";
import { connect } from "react-redux";
import { setHotelAssign } from "../../../services/actions";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { checkIfEmpty, checkIfNotEmpty } from "../../../utils/utils";
import toast from "react-hot-toast";
const cookies = new Cookies();
function EditUser({ globalReducer, setHotelAssigns }) {
  var token = cookies.get("nw_token");
  var user = cookies.get("nw_post");
  var history = useHistory();
  const fileUpload = useRef(null);
  function handleImageClick() {
    fileUpload.current.click();
  }
  const uploadImage = (e) => {
    var file = e.target.files[0];
    setManagerEditImage(file);
    setManagerImage(URL.createObjectURL(file));
  };
  var url = window.location.pathname;
  var id = url.substring(url.lastIndexOf("/") + 1);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("Test$1234");
  const [contact, setContact] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [managerImage, setManagerImage] = useState("");
  const [managerEditImage, setManagerEditImage] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const populateUser = () => {
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("userId", id);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/getUserIndividualData`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        setFname(response.data.userData.first_name);
        setLname(response.data.userData.last_name);
        setEmail(response.data.userData.email);
        setContact(response.data.userData.phone);
        setManagerImage(response.data.userData.profilePhoto);
        setHotelAssigns(response.data.userData.hotelList);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  useEffect(() => {
    populateUser();
  }, []);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const passwordShow = () => {
    setIsShowPassword(!isShowPassword);
  };
  const activeHotel = () => {
    setActiveStatus(!activeStatus);
  };
  const updateManagerDetail = (e) => {
    if (validateFields()) {
      e.preventDefault();
      var fd = new FormData();
      fd.append("token", JSON.stringify(token));
      fd.append("identity", "norwoodinns");
      fd.append("userId", id);
      fd.append("firstName", fname);
      fd.append("lastName", lname);
      {
        email && fd.append("email", email);
      }
      {
        password && fd.append("password", password);
      }
      fd.append("phone", contact);
      {
        managerEditImage && fd.append("profilePhoto", managerEditImage);
      }
      axios({
        method: "POST",
        url: `${config.base_url}Hoteladmin/updateUserDetails`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: fd,
      })
        .then(function (response) {
          console.log(response);
          setIsLoading(false);
          alert("Update Done Succesfully");
        })
        .catch(function (err) {
          console.log(err);
          setIsLoading(false);
          console.log(err.response.data.message);
        });
    }
  };

  const validateFields = () => {
    let isValid = true;

    if (checkIfEmpty(fname)) {
      isValid = false;
      toast.error("Please enter first name");
    }

    if (checkIfEmpty(lname)) {
      isValid = false;
      toast.error("Please enter last name");
    }

    if (checkIfEmpty(email)) {
      isValid = false;
      toast.error("Please enter email");
    }

    if (checkIfEmpty(contact)) {
      isValid = false;
      toast.error("Please enter contact");
    }

    return isValid;
  };

  const asignManager = (e) => {
    e.preventDefault();
    var fd = new FormData();
    fd.append("token", JSON.stringify(token));
    fd.append("identity", "norwoodinns");
    fd.append("hotelList", JSON.stringify(globalReducer.hotelAssignedIds));
    fd.append("hotelManager", id);
    axios({
      method: "POST",
      url: `${config.base_url}Hoteladmin/assignHotelManager`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  return (
    <>
      {user === "admin" ? (
        <Container
          className="py-8"
          style={{ minHeight: "calc(100vh - 130px)" }}
        >
          <Row>
            <Col md={3}>
              <div>
                <div className="mb-3" onClick={() => handleImageClick()}>
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    ref={fileUpload}
                    onChange={(e) => uploadImage(e)}
                  />
                  <img
                    src={managerImage}
                    alt="hotel"
                    className="w-full h-52 object-contain p-1 bg-white border-gray-300 border border-solid rounded max-w-full cursor-pointer"
                  />
                </div>
              </div>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={6}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      First Name
                    </label>
                    <input
                      className="nr_form_control"
                      type="text"
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Last Name
                    </label>
                    <input
                      className="nr_form_control"
                      type="text"
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Email
                    </label>
                    <input
                      className="nr_form_control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Contact
                    </label>
                    <input
                      className="nr_form_control"
                      type="email"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="w-full mb-4">
                    <label className="inline-block mb-2 max-w-full text-sm text-gray-500">
                      Password
                    </label>
                    <div className="flex relative">
                      <input
                        className="nr_form_control"
                        type={isShowPassword ? "text" : "password"}
                        defaultValue="Test$1234"
                        readOnly
                      />
                      <div>
                        <img
                          src={isShowPassword ? eye : noteye}
                          alt="eye"
                          className="passwordEye w-6 absolute right-4 top-1 cursor-pointer"
                          onClick={() => passwordShow()}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <ListinputSearch />
                </Col>
                <Col md={2}>
                  <Button
                    className="w-full rounded py-2 px-2 text-white roboto"
                    type="button"
                    style={{ backgroundColor: "var(--primaryHotel)" }}
                    onClick={(e) => {
                      updateManagerDetail(e);
                      asignManager(e);
                    }}
                  >
                    {isLoading ? "Loading..." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        history.replace("/")
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToState = (dispatch) => {
  return {
    setHotelAssigns: (value) => {
      dispatch(setHotelAssign(value));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToState)(EditUser);
