import ClipLoader from "react-spinners/ClipLoader";


const override = {
    display: "block",
    margin: "0 auto",
};
const FadeLoader = () => {
    return (
        <ClipLoader
            color={'#19548b'}
            loading={true}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}

export default FadeLoader